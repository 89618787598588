import avatar from '@assets/avatar_180px.svg';
import { Group, Image, Stack } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

export const PharmaMethod: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context, funnelApi }) => {
  const { t } = useTranslation();
  const { partnerInfo } = context;
  return (
    <FullScreenContainer>
      <Stack flex={1} justify="center" gap={'lg'}>
        <Stack my="xl">
          <Stack gap={'md'}>
            <Group justify="center" align="center">
              {partnerInfo?.logoImageUrl ? (
                <Image src={partnerInfo?.logoImageUrl} maw={140} mah={140} />
              ) : (
                <Image src={avatar} w={80} h={80} />
              )}
            </Group>
            <StepTitle title={t('emblaIsYourPartnerInLongTermWeightLoss')} description={t('withOrWithoutMedicine')} />
          </Stack>
        </Stack>
      </Stack>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({})} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
