import { Card, Divider, Group, Stack, Text } from '@mantine/core';
import { PageTemplates } from '@pages/shared/pageTemplates';
import { EmblaIconName, Icon } from '@shared/components/Icon';
import { useTheme } from '@shared/theme';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { t } from '@t';

const InfoCard: FunctionComponent<{
  title: string;
  description: string;
  icon: EmblaIconName;
}> = ({ title, description, icon }) => {
  const { theme } = useTheme();
  const emblaTheme = theme.other.theme;
  return (
    <Card flex={1} p="md" withBorder>
      <Stack gap="sm">
        <Group justify={'center'} p={'sm'} style={{ borderRadius: 8 }} bg={emblaTheme.screen.brand}>
          <Icon size={50} name={icon} />
        </Group>
        <Text size={'lg'} fw={600} ta="center">
          {title}
        </Text>
        <Divider h={1} color="black" opacity={0.1} />
        <Text ta="center" data-secondary>
          {description}
        </Text>
      </Stack>
    </Card>
  );
};
const Content: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Group align="stretch">
      <InfoCard
        icon={'icon-fi-rr-medicine'}
        title={t('medicalWeightLoss.title')}
        description={t('medicalWeightLoss.text')}
      />
      <InfoCard icon={'icon-fi-rr-users'} title={t('coaching.title')} description={t('coaching.text')} />
    </Group>
  );
};

export const WhoCanWeHelp = PageTemplates.TitleText({
  title: t('whoCanWeHelp.title'),
  text: t('whoCanWeHelp.text'),
  content: <Content />,
});
