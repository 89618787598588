import { BulletPoints, getItems } from '@components/ProductCard';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Card, Checkbox, Divider, Group, Stack, Text } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import { FinancialPrice } from '@shared/gql/sdk';
import { getProductNameByProduct } from '@shared/helpers';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { ProductSelection } from 'src/FunnelContext';

import { t } from '@t';

interface IProps {
  price: FinancialPrice;
  product: ProductSelection;
  selected?: boolean;
}

export const ProductCardSelect: FunctionComponent<IProps> = ({ product, selected, price }) => {
  const getPriceIconName = () => {
    return product === ProductSelection.MedicationAndCoaching ? 'icon-fi-rr-medicine' : 'icon-fi-rr-users';
  };

  const getProductShortDescription = (productSelection: ProductSelection): string => {
    return productSelection === ProductSelection.MedicationAndCoaching
      ? t('medicalWeightLossProgram')
      : t('weightLossCoachingProgram');
  };

  const { isMobile } = useIsMobileViewport();
  return (
    <Card data-selected={selected}>
      <Group wrap="nowrap">
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          data-background={true}
          px={12}
          py={'md'}
        >
          <Icon name={getPriceIconName()} size={24} />
        </Card>
        <Stack style={{ flexGrow: 1 }} gap={0}>
          <Group justify="space-between" wrap="nowrap" align="top">
            <Text fw={isMobile ? 600 : 400} size={isMobile ? '18px' : '24px'}>
              {getProductNameByProduct(price.product)}
            </Text>
            {selected !== undefined && <Checkbox checked={selected} readOnly />}
          </Group>
          <Text fw={400} data-secondary>
            {getProductShortDescription(product)}
          </Text>
        </Stack>
      </Group>
      <Divider my="md" />
      <Stack gap={'md'}>
        <BulletPoints items={getItems(product)} />
        <Disclaimer product={product} selected={selected} />
      </Stack>
    </Card>
  );
};

const Disclaimer: FunctionComponent<{ product: ProductSelection; selected?: boolean }> = ({ product, selected }) => {
  const content =
    product === ProductSelection.MedicationAndCoaching ? (
      <Stack>
        <Text data-secondary>
          <Trans i18nKey={'thisIsForYouIfYouWantGLP1'} components={{ b: <span style={{ fontWeight: 700 }} /> }} />
        </Text>
        <Text data-secondary>
          <Trans i18nKey={'medicationIsNotIncluded'} components={{ b: <span style={{ fontWeight: 700 }} /> }} />
        </Text>
      </Stack>
    ) : product === ProductSelection.CoachingOnly ? (
      <Text data-secondary>
        <Trans i18nKey={'thisIsForYouIfYouDontWantGLP1'} components={{ b: <span style={{ fontWeight: 700 }} /> }} />
      </Text>
    ) : null;

  if (selected) {
    return (
      <Card radius={'md'} data-background-transparent>
        {content}
      </Card>
    );
  } else {
    return (
      <Card radius={'md'} data-background-dark>
        {content}
      </Card>
    );
  }
};
