import { Card, Divider, Group, Stack, Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  items: { label: string | ReactNode; value: string | ReactNode }[];
  total: string | ReactNode;
}
export const Receipt: React.FC<Props> = ({ items, total }) => {
  const { t } = useTranslation();

  const renderValue = (item: string | ReactNode, textProps?: TextProps) => {
    if (typeof item === 'string')
      return (
        <Text fw={600} {...textProps}>
          {item}
        </Text>
      );
    return item;
  };
  return (
    <Card>
      <Stack>
        {items.map((item) => (
          <Group justify="space-between">
            {typeof item.label === 'string' ? <Text data-secondary>{item.label}</Text> : item.label}
            {renderValue(item.value)}
          </Group>
        ))}
      </Stack>
      <Divider my="md" />
      <Group justify="space-between">
        <Text fw="bold">{t('total')}</Text>
        {renderValue(total)}
      </Group>
    </Card>
  );
};
