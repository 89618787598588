import { Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaNumberInput } from '@shared/components/EmblaNumberInput';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { LeftLabel } from '@shared/components/LeftLabel';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { cmToFeetInches, feetInchesToCm, kgToPounds, poundsToKg } from '@shared/unitConversion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

const numberRequired = (value: number, message?: string) => {
  if (value == null) {
    return message || t('required');
  }
};
const numberInRange = (value: number, range: [number?, number?], msg?: string) => {
  if ((range[0] != null && value < range[0]) || (range[1] != null && value > range[1])) {
    if (msg) return msg;
    let message = t('notInRange');
    if (range[0] != null && range[1] != null) {
      message = t('valueMustBeBetween', { min: range[0], max: range[1] });
    } else if (range[0] == null) {
      message = t('valueMustBeLessThan', { max: range[1] });
    } else if (range[1] == null) {
      message = t('valueMustBeGreaterThan', { min: range[0] });
    }
    return message;
  }
};

const calculateFormFromUserData = (data: { height?: number; weight?: number }) => {
  return {
    height: data.height || undefined,
    weight: data.weight || undefined,
    feet: cmToFeetInches(data.height).feet,
    inches: cmToFeetInches(data.height).inches,
    pounds: kgToPounds(data.weight),
  };
};

export const HeightWeight: FunnelPageComponent<
  {
    height: number;
    weight: number;
  },
  IFunnelContext
> = ({ data: { height, weight }, funnelApi }) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: calculateFormFromUserData({ height, weight }),

    validate: {
      feet: (value) => {
        const required = numberRequired(value, t('heightFtRequired'));
        const inRange = numberInRange(value, [4, 7]);
        return required || inRange;
      },
      inches: (value) => {
        const required = numberRequired(value, t('heightInRequired'));
        const inRange = numberInRange(value, [0, 11]);
        return required || inRange;
      },
      pounds: (value) => {
        const required = numberRequired(value, t('weightLbsRequired'));
        const inRange = numberInRange(value, [80, null]);
        return required || inRange;
      },
    },
  });

  useEffect(() => {
    const newValues = calculateFormFromUserData({ height, weight });
    form.setValues(newValues);
  }, []);

  const onChange = (field: string) => (value: string | number) => {
    let { feet, inches, pounds } = form.values;

    switch (field) {
      case 'feet':
        feet = parseFloat(String(value));
        break;
      case 'inches':
        inches = parseFloat(String(value));
        break;
      case 'pounds':
        pounds = parseFloat(String(value));
        break;
    }

    const height = feetInchesToCm(feet, inches);
    const weight = poundsToKg(pounds);

    form.setValues({
      height,
      weight,
      feet,
      inches,
      pounds,
    });
  };

  return (
    <form
      onSubmit={form.onSubmit(() => {
        funnelApi.next({
          height: form.values.height,
          weight: form.values.weight,
        });
      })}
      style={{ flex: 1 }}
    >
      <FullScreenContainer>
        <StepTitle title={t('whatsYourHeightAndWeight2')} description={t('toKnowIfYoureAMatchWeNeedYourWeight')} />

        <Stack gap={'xl'}>
          <LeftLabel label={t('myHeight')}>
            <Group align="center" gap="sm" w="13rem" wrap="nowrap">
              <EmblaNumberInput
                {...form.getInputProps('feet')}
                mode="measurement"
                suffix="ft"
                onChange={onChange('feet')}
                required
              />
              <EmblaNumberInput
                {...form.getInputProps('inches')}
                mode="measurement"
                suffix="in"
                onChange={onChange('inches')}
                required
              />
            </Group>
          </LeftLabel>

          <LeftLabel label={t('myWeight')}>
            <EmblaNumberInput
              {...form.getInputProps('pounds')}
              mode="measurement"
              width="13rem"
              suffix="lbs"
              onChange={onChange('pounds')}
              required
            />
          </LeftLabel>
        </Stack>
        <BottomScreenContainer>
          <NextButton type="submit" disabled={!form.isValid()} />
          <BackButton onClick={() => funnelApi.back()} />
        </BottomScreenContainer>
      </FullScreenContainer>
    </form>
  );
};
