import { createEnterMeasurementsPage } from '@pages/shared/partner/EnterMeasurements';
import api from '@shared/api';
import { FunnelPageData } from '@shared/funnel-engine';
import { MeasurementType } from '@shared/gql/sdk';

type EnterMeasurementData = FunnelPageData<ReturnType<typeof createEnterMeasurementsPage>>;

export const getCreateMeasurementInputs = (data: EnterMeasurementData) => {
  if (!data) return [];
  const hasWeight = data.weightKg;
  const hasHbA1c = data.hba1c;
  const hasBloodPressure = data.bloodPressureSystolic && data.bloodPressureDiastolic;
  const hasCholesterol = data.cholesterolHdl && data.cholesterolLdl && data.cholesterolTotal;

  const timestamp = new Date().toISOString();
  return [
    ...(hasWeight
      ? [
          {
            type: MeasurementType.WeightKg,
            value: String(data.weightKg),
            timestamp,
          },
        ]
      : []),
    ...(hasHbA1c
      ? [
          {
            type: MeasurementType.HbA1c,
            value: String(data.hba1c),
            timestamp,
          },
        ]
      : []),
    ...(hasBloodPressure
      ? [
          {
            type: MeasurementType.BloodPressure,
            value: '',
            complexValue: {
              type: MeasurementType.BloodPressure,
              systolic: data.bloodPressureSystolic,
              diastolic: data.bloodPressureDiastolic,
            },
            timestamp,
          },
        ]
      : []),
    ...(hasCholesterol
      ? [
          {
            type: MeasurementType.Cholesterol,
            value: '',
            complexValue: {
              type: MeasurementType.Cholesterol,
              total: data.cholesterolTotal,
              ldl: data.cholesterolLdl,
              hdl: data.cholesterolHdl,
            },
            timestamp,
          },
        ]
      : []),
  ];
};
export const saveMeasurements = async (data: EnterMeasurementData, clientPartnerAccessCode?: string) => {
  const measurementsToAdd = getCreateMeasurementInputs(data);

  if (measurementsToAdd.length > 0) {
    await api.AddMeasurements({
      clientPartnerAccessCode: clientPartnerAccessCode,
      data: measurementsToAdd,
    });

    return measurementsToAdd;
  } else {
    return [];
  }
};
