import { Card, Group, Text, ThemeIcon } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import React, { FunctionComponent } from 'react';

export const BulletpointCard: FunctionComponent<{ label: React.ReactNode }> = ({ label }) => {
  return (
    <Card shadow="sm">
      <Group align="center">
        <ThemeIcon h={32} w={32}>
          <Icon name={'icon-fi-rr-check'} />
        </ThemeIcon>
        <Text size={'lg'} data-secondary flex={1}>
          {label}
        </Text>
      </Group>
    </Card>
  );
};
