import phoneImg from '@assets/mobile-phone.png';
import { Flex, Image } from '@mantine/core';
import { FunctionComponent } from 'react';

interface IProps {
  width?: number;
}

export const PhoneImage: FunctionComponent<IProps> = ({ width = 250 }) => {
  return (
    <Flex justify="center">
      <Image maw={width} src={phoneImg} style={{ zIndex: -1 }} />
    </Flex>
  );
};
