import { SexAssignedSelection } from '@components/SexAssignedSelection';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { SexAssignedAtBirth } from '@shared/gql/sdk';
import { useState } from 'react';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const SexAssigned: FunnelPageComponent<{ value?: SexAssignedAtBirth }, IFunnelContext> = ({
  data: { value },
  funnelApi,
}) => {
  const [sexAssignedAtBirth, setSexAssignedAtBirth] = useState<SexAssignedAtBirth>(value);

  return (
    <FullScreenContainer>
      <StepTitle title={t('whatIsYourSexAssignedAtBirth')} />
      <SexAssignedSelection
        sexAssignedAtBirth={sexAssignedAtBirth}
        setSexAssignedAtBirth={setSexAssignedAtBirth}
        showPreferNotToSay={true}
      />
      <BottomScreenContainer>
        <NextButton disabled={!sexAssignedAtBirth} onClick={() => funnelApi.next({ value: sexAssignedAtBirth })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
