import { Box, BoxProps } from '@mantine/core';
import { FunctionComponent } from 'react';

export const EmblaInnerContainer: FunctionComponent<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box style={{ alignSelf: 'center' }} w="100%" maw={480} h={'100%'} {...props}>
      {children}
    </Box>
  );
};
