import { ButtonProps } from '@mantine/core';
import { OS, useOs } from '@mantine/hooks';
import { Icon } from '@shared/components/Icon';
import { useTranslation } from 'react-i18next';

import { GrowingButton } from './GrowingButton';

interface IProps {
  disabled?: boolean;
  size?: ButtonProps['size'];
  onClick?: () => void;
}

const iosUrl = 'https://apps.apple.com/app/embla/id1607818562';
const androidUrl = 'https://play.google.com/store/apps/details?id=com.joinembla.embla1';

const downloadUrlMap: Record<OS, string> = {
  ios: iosUrl,
  macos: iosUrl,
  android: androidUrl,
  windows: androidUrl,
  linux: androidUrl,
  undetermined: androidUrl,
};

export const DownloadButton: React.FunctionComponent<IProps> = ({ disabled, size, onClick }) => {
  const { t } = useTranslation();
  const os = useOs();

  return (
    <GrowingButton
      size={size}
      leftSection={<Icon name={'icon-fi-rr-download'} size={iconSize(size)} />}
      disabled={disabled}
      data-testid="download"
      pt={'xs'}
      onClick={onClick}
      href={downloadUrlMap[os]}
      buttonLabel={t('downLoadTheApp')}
    />
  );
};

const iconSize = (size: ButtonProps['size']) => {
  switch (size) {
    case 'xs':
    case 'sm':
    case 'compact-xs':
    case 'compact-sm':
      return 16;
    default:
      return 20;
  }
};
