import { Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useMemo } from 'react';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const NotEligible: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context, funnelApi }) => {
  const { embedded, notEligibleReason, measurements } = context;
  const { title, mdText1, mdText2 } = useMemo(() => {
    const bmi = measurements?.bmi ?? undefined;
    return getTexts(notEligibleReason, bmi);
  }, [notEligibleReason, measurements]);

  const showDownloadButton = notEligibleReason === 'low-bmi' || notEligibleReason === 'addiction';

  return (
    <FullScreenContainer gap="lg" py="md">
      <StepTitle title={title} description={mdText1} description2={mdText2} />
      <BottomScreenContainer>
        {showDownloadButton && !embedded ? (
          <>
            <Text ta="center" data-primary size="lg" fw={600}>
              {t('youAreWelcomeToDownload')}
            </Text>
            <DownloadButton />
            <BackButton onClick={() => funnelApi.back()} />
            <PhoneImage />
          </>
        ) : (
          <>
            {showDownloadButton && embedded && (
              <Text ta="center" data-primary size="lg" fw={600}>
                {t('youAreWelcomeToUse')}
              </Text>
            )}
            <BackButton
              onClick={() => {
                funnelApi.back();
              }}
            />
          </>
        )}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const getTexts = (reason: IFunnelContext['notEligibleReason'], bmi?: number) => {
  const contentMap: Record<
    string,
    {
      title?: string;
      mdText1: string;
      mdText2?: string;
    }
  > = {
    'low-bmi': {
      mdText1: t('notEligibleBmi3', { bmi }),
      title: t('unfortunatelyWeCantOfferYouTreatment'),
    },
    'very-low-bmi': {
      mdText1: t('notEligibleBmi3', { bmi }),
      title: t('unfortunatelyWeCantOfferYouTreatment'),
    },
    'eating-disorder': {
      mdText1: t('notEligibleEatingDisorder'),
    },
    addiction: {
      mdText1: t('notEligibleDrugsAlcohol'),
    },
    'invalid-age': {
      mdText1: t('declinedInvalidAge.text'),
      title: t('unfortunatelyWeCantOfferYouTreatment'),
    },
  };

  const { title, mdText1, mdText2 } = contentMap[reason] || {};

  return {
    title: title || t('thanksForYourAnswers'),
    mdText1,
    mdText2,
  };
};
