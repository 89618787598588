import { ProductCardSelect } from '@components/ProductCardSelect';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Group, Loader, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

export const Recommendation: FunnelPageComponent<
  {
    selectedProduct: ProductSelection;
  },
  IFunnelContext
> = ({ context: { selectedProduct, selectedProductPriceInfo: price }, funnelApi }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileViewport();
  if (!price) return <Loader />;

  return (
    <FullScreenContainer>
      <StepTitle title={t('weBelieveThisProgramFitsYouBest')} />
      <Group justify="center">
        <Stack gap="lg">
          <Stack gap={0} flex={1} maw={!isMobile ? 420 : '100%'}>
            <Text py="xs" fw="bold">
              {t('yourRecommendedProgram')}
            </Text>
            <ProductCardSelect product={ProductSelection.MedicationAndCoaching} price={price} />
          </Stack>
          <TrustBoxMicroTrustScore />
        </Stack>
      </Group>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({ selectedProduct })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
