import { ActionIcon, Input } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Icon } from '@shared/components/Icon';
import dayjs from 'dayjs';
import { ComponentProps, FunctionComponent, forwardRef, useRef } from 'react';
import { IMask, IMaskInput } from 'react-imask';
import { resolveIntl } from 'src/resolveIntl';

type DateWithMaskInputValue = { date: Date; dateString: string; valid: boolean };

const MaskedDateInput = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<typeof IMaskInput>, 'value'> & {
    error?: string;
    onDateChange: (date: Date) => void;
    value: DateWithMaskInputValue;
  }
>(({ label, required, error, ...props }, ref) => {
  const pickerRef = useRef<HTMLButtonElement>();
  return (
    <>
      <Input.Wrapper label={label} error={error} required={required}>
        <Input
          {...props}
          value={props.value?.dateString}
          size={'lg'}
          component={IMaskInput}
          ref={ref}
          rightSection={
            <>
              <ActionIcon
                variant={'subtle'}
                onClick={() => {
                  pickerRef.current?.click();
                }}
              >
                <Icon name="icon-fi-rr-calendar-2" />
              </ActionIcon>
            </>
          }
          rightSectionPointerEvents={'all'}
        />
      </Input.Wrapper>
      <DatePickerInput
        style={{ visibility: 'hidden', position: 'absolute', right: 0, bottom: 0 }}
        ref={pickerRef}
        onChange={(date) => {
          props.onDateChange(date);
        }}
        value={props.value?.date}
        dropdownType="modal"
        modalProps={{ centered: true }}
      />
    </>
  );
});

export const DateInputWithMask: FunctionComponent<{
  value?: DateWithMaskInputValue;
  onChange: (value: DateWithMaskInputValue) => void;
  error?: string;
}> = (props) => {
  const { dateFormat } = resolveIntl();

  const mask = dateFormat.replace(/\d/g, '0');

  return (
    <MaskedDateInput
      error={props.error}
      mask={mask}
      // @ts-expect-error don't know why
      lazy={false}
      blocks={{
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          placeholderChar: 'D',
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          placeholderChar: 'M',
        },
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 2025,
          placeholderChar: 'Y',
        },
      }}
      // input change handler
      onAccept={(value) => {
        const date = value ? dayjs(value).startOf('day').toDate() : null;
        const valid = dayjs(value, dateFormat, true).isValid();
        props.onChange({
          date: valid ? date : props.value?.date,
          valid,
          dateString: value,
        });
      }}
      // datepicker change handler
      onDateChange={(value) => {
        const date = dayjs(value).startOf('day').toDate();
        const valid = dayjs(value).isValid();
        props.onChange({
          date,
          valid,
          dateString: dayjs(date).format(dateFormat),
        });
      }}
      value={props.value}
    />
  );
};
