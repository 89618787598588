import { Card, Grid, Paper, Text, ThemeIcon } from '@mantine/core';
import { EmblaIconName, Icon } from '@shared/components/Icon';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

export const textElement = (key: string) => {
  return (
    <Text>
      <Trans components={{ strong: <b /> }} i18nKey={key} />
    </Text>
  );
};

export const gridElement = (
  key: string,
  text: React.ReactNode,
  icon: EmblaIconName,
  backgroundVariant?: InfoCardBackgroundVariant,
) => (
  <Grid.Col span={'content'} key={key}>
    <InfoCard iconName={icon} text={text} backgroundVariant={backgroundVariant} />
  </Grid.Col>
);

type InfoCardBackgroundVariant = 'variant1' | 'variant2' | 'variant3' | 'variant4';

interface InfoCardProps {
  iconName: EmblaIconName;
  text: string | React.ReactNode;
  backgroundVariant?: InfoCardBackgroundVariant;
}

const InfoCard: FunctionComponent<InfoCardProps> = ({ iconName, text, backgroundVariant }) => {
  const backgroundMap: Record<InfoCardBackgroundVariant, string> = {
    variant1: 'var(--embla-theme-rainbow-background-v1)',
    variant2: 'var(--embla-theme-rainbow-background-v3)',
    variant3: 'var(--embla-theme-rainbow-background-v6)',
    variant4: 'var(--embla-theme-rainbow-background-v5)',
  };
  return (
    <Card w={164} h={backgroundVariant ? 190 : 160} radius={'lg'} shadow="md" style={{ alignItems: 'center' }}>
      <Paper
        p={backgroundVariant ? 20 : 0}
        radius={'50%'}
        bg={backgroundVariant ? backgroundMap[backgroundVariant] : undefined}
      >
        <ThemeIcon>
          <Icon name={iconName} size={24} />
        </ThemeIcon>
      </Paper>

      <Text size="md" ta={'center'} mt={'sm'}>
        {text}
      </Text>
    </Card>
  );
};
