import { Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { CoachingAndMedication } from './CoachingAndMedicationChart';
import { Coaching } from './CoachingOnlyChart';

export const EmblaMethodChart: FunnelPageComponent<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  IFunnelContext,
  undefined
> = ({ funnelApi, context }) => {
  const {
    selectedProduct,
    measurements: { weight, preferredUnitSystem: unitSystem },
  } = context || {};

  return (
    <FullScreenContainer>
      <Stack align="center" gap="lg" flex={1}>
        {selectedProduct === ProductSelection.CoachingAndMaybeMedication ||
        selectedProduct === ProductSelection.MedicationAndCoaching ? (
          <CoachingAndMedication weight={weight} unitSystem={unitSystem} />
        ) : selectedProduct === ProductSelection.CoachingOnly ? (
          <Coaching />
        ) : null}
      </Stack>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
