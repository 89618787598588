import { Button, Flex, Loader, Stack } from '@mantine/core';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { Disclaimer } from '@shared/components/Disclaimer';
import { EmblaPhoneInput } from '@shared/components/EmblaPhoneInput';
import { Icon } from '@shared/components/Icon';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { OtpState, PinCodeInput } from '@shared/components/PinCodeInput';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { showErrorNotification } from '@shared/showNotification';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';
export enum ConfirmPhoneNumberErrors {
  AppointmentNotAvailable = 'appointment-not-available',
}
export const ConfirmPhoneNumber: FunnelPageComponent<
  { userPhoneNumber: string; phoneChanged?: boolean },
  IFunnelContext,
  ConfirmPhoneNumberErrors,
  {
    confirmPhoneNumber: (phoneNumber: string, code: string) => Promise<boolean>;
    sendSMSCode: (phoneNumber: string) => Promise<void>;
  }
> = ({ context: { userSession }, funnelApi }, { flowVariant }) => {
  const initialPhoneNumber = userSession?.phoneNumber;
  const [confirmingPhoneNumber, setConfirmingPhoneNumber] = useState(false);
  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [otpState, setOtpState] = useState<OtpState>('sent');

  const [phoneNumber, setPhoneNumber] = useState<{
    value: string;
    valid: boolean;
  }>({
    value: initialPhoneNumber,
    valid: initialPhoneNumber && isValidPhoneNumber(initialPhoneNumber),
  });

  useEffect(() => {
    setPhoneNumber({
      value: initialPhoneNumber,
      valid: initialPhoneNumber && isValidPhoneNumber(initialPhoneNumber),
    });
  }, [initialPhoneNumber]);

  const resend = async (phoneNumber: string) => {
    setOtpState('sending');

    await funnelApi.actions.sendSMSCode(phoneNumber);

    setOtpState('sent');
  };

  const nextLockRef = useRef(false);

  const next = useCallback(async () => {
    if (nextLockRef.current) return;
    nextLockRef.current = true;

    setConfirmingPhoneNumber(true);
    setOtpState('confirming');
    if (await funnelApi.actions.confirmPhoneNumber(phoneNumber.value, pin)) {
      funnelApi.next({
        userPhoneNumber: phoneNumber.value,
        phoneChanged: initialPhoneNumber !== phoneNumber.value,
      });
    } else {
      nextLockRef.current = false;
      showErrorNotification(t('wrongOtp'), 3000);
      setOtpState('confirm_fail');
    }
    setConfirmingPhoneNumber(false);
  }, [funnelApi, phoneNumber, pin]);

  useEffect(() => {
    if (pin?.length === 6) {
      next();
    }
  }, [pin, next]);

  const sending = otpState === 'sending';

  return (
    <FullScreenContainer>
      <Stack gap={'md'} align="center">
        {otpState === 'send_fail' ? (
          <Disclaimer message={t('errorSendingOtp')} pillText={t('badgeErrorSendingOtp')} type="error" />
        ) : null}
        <StepTitle
          title={flowVariant === 'booking' ? t('yourCallIsBookedConfirmPhoneNumber') : t('confirmPhoneNumber')}
          description={
            phoneNumber.value ? (
              <Trans
                i18nKey="weHaveSentCode"
                values={{
                  phoneNumber: parsePhoneNumber(initialPhoneNumber).formatNational(),
                }}
                components={{
                  nowrap: <span style={{ whiteSpace: 'nowrap' }} />,
                }}
              />
            ) : null
          }
        />

        {flowVariant !== 'phone-already-registered' && (
          <EmblaPhoneInput
            value={phoneNumber.value}
            onPhoneNumberChange={(value, valid) => {
              if (valid === undefined) return;
              setPhoneNumber({
                value,
                valid,
              });
            }}
          />
        )}

        <PinCodeInput size={6} onChange={setPin} otpState={otpState} />
        <Button
          disabled={!phoneNumber.value || sending}
          variant="transparent"
          size="md"
          leftSection={
            <Flex mr="xs" justify="center" align="center">
              {sending ? <Loader size={'sm'} /> : <Icon size={16} name={'icon-fi-rr-refresh'} />}
            </Flex>
          }
          onClick={() => resend(phoneNumber.value)}
        >
          {t('resend')}
        </Button>
      </Stack>
      <BottomScreenContainer>
        <GrowingButton
          data-testid="next"
          disabled={!pin?.length}
          loading={sending || confirmingPhoneNumber}
          type="button"
          onClick={next}
          buttonLabel={t('confirm')}
        />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
