import { env } from '@env';
import { useEffect, useState } from 'react';

export const useRecaptcha = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const grecaptcha = (window as any).grecaptcha;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (grecaptcha != null) {
      setIsReady(true);
    }
  }, [grecaptcha]);

  useEffect(() => {
    if (!env.recaptchaKey) {
      setIsReady(false);
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${env.recaptchaKey}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getToken = async (action: string): Promise<string> => {
    if (isReady) {
      return new Promise((resolve: (token: string) => void, reject) => {
        grecaptcha.enterprise.ready(async () => {
          try {
            const token = await grecaptcha.enterprise.execute(env.recaptchaKey, { action });
            resolve(token);
          } catch (error) {
            reject(error);
          }
        });
      });
    }
  };
  return { isReady, getToken };
};
