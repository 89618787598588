import avatar from '@assets/avatar_180px.svg';
import { Group, Image, Stack } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaInnerContainer } from '@shared/components/EmblaInnerContainer';
import { EmblaTextInput } from '@shared/components/EmblaTextInput';
import { EmblaTopContainer } from '@shared/components/EmblaTopContainer';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

export enum EnterCodeErrors {
  InvalidCode = 'invalid-code',
}

export const EnterCode: FunnelPageComponent<{ code: string }, IFunnelContext, EnterCodeErrors> = ({
  funnelApi,
  context,
}) => {
  const { t } = useTranslation();
  const { partnerInfo } = context;
  const [userCode, setUserCode] = useState<string>();

  return (
    <EmblaTopContainer>
      <EmblaInnerContainer maw={480}>
        <FullScreenContainer>
          <Stack flex={1} justify="center" align="center" gap={'md'} mt={120}>
            <Group justify="center" align="center">
              {partnerInfo?.logoImageUrl ? (
                <Image src={partnerInfo?.logoImageUrl} maw={140} mah={140} />
              ) : (
                <Image src={avatar} w={80} h={80} />
              )}
            </Group>
            <StepTitle title={t('enterCode.title')} description={t('enterCode.description')} />
            <EmblaTextInput
              style={{ width: '100%' }}
              maw={400}
              error={funnelApi.error}
              value={userCode}
              withAsterisk
              placeholder={t('partnerId')}
              onChange={(e) => {
                setUserCode(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                console.log(e.key);
                if (e.key === 'Enter') {
                  funnelApi.next({ code: userCode });
                }
              }}
            />
          </Stack>
          <BottomScreenContainer>
            <NextButton onClick={() => funnelApi.next({ code: userCode })} />
          </BottomScreenContainer>
        </FullScreenContainer>
      </EmblaInnerContainer>
    </EmblaTopContainer>
  );
};
