import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { useEffect, useRef } from 'react';

import { createV3FunnelConfig as createV3FunnelCallbackConfig } from './configs/uk-checkout-or-book/config';
import { IFunnelSession } from './FunnelContext';
import { hydrateDataFromSession } from './util/hydration';

import { t } from '@t';

import './FunnelUK.css';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'details':
      return t('topDetails');
    case 'program':
      return t('topProgram');
    case 'eligibility':
      return t('topEligibility');
    case 'checkout':
      return t('topCheckout');
  }
};

const getCallbackRequestConfig = (session: IFunnelSession) => {
  const config = createV3FunnelCallbackConfig({ ...session.context, source: 'SIGNUP-FUNNEL' });
  config.initialData = hydrateDataFromSession(config.config, session);
  return config;
};

const FunnelUK: React.FunctionComponent<{
  session: IFunnelSession;
}> = ({ session }) => {
  const funnelCallbackConfig = useRef(getCallbackRequestConfig(session));

  useEffect(() => {
    funnelCallbackConfig.current = getCallbackRequestConfig(session);
  }, [session]);

  return (
    <Funnel
      name="v3-uk-with-checkout-or-book"
      debug={debugEnabled()}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options={funnelCallbackConfig.current}
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
    />
  );
};

export default FunnelUK;
