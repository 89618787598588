import { Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const NotTexas: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context, funnelApi }) => {
  const { embedded } = context;

  return (
    <FullScreenContainer gap="lg" py="md">
      <StepTitle title={t('unfortunatelyWeCannotOfferTreatment')} description={t('weProvideOurServiceOnlyInTexas')} />
      <BottomScreenContainer>
        {!embedded ? (
          <>
            <Text ta="center" data-primary size="lg" fw={600}>
              {t('youAreWelcomeToDownload')}
            </Text>
            <DownloadButton />
            <BackButton onClick={() => funnelApi.back()} />
            <PhoneImage />
          </>
        ) : (
          <>
            <Text ta="center" data-primary size="lg" fw={600}>
              {t('youAreWelcomeToUse')}
            </Text>
            <BackButton
              onClick={() => {
                funnelApi.back();
              }}
            />
          </>
        )}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
