import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Stack, Text, Title, TitleProps } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

interface IProps extends Omit<TitleProps, 'title' | 'size'> {
  title: ReactNode;
  description?: ReactNode;
  description2?: ReactNode;
}

export const StepTitle: FunctionComponent<IProps> = ({ title, description, description2, ...titleProps }) => {
  const { isMobile } = useIsMobileViewport();
  return (
    <Stack justify="center" align="center" gap={'xxl'}>
      <Title ta="center" size={getSize(isMobile)} lh={getLh(isMobile)} fw={600} {...titleProps}>
        {title}
      </Title>
      {description ? (
        <Text size={'lg'} ta={'center'} data-secondary data-secondary-font>
          {description}
        </Text>
      ) : null}
      {description2 ? (
        <Text size={'lg'} ta={'center'} data-secondary data-secondary-font>
          {description2}
        </Text>
      ) : null}
    </Stack>
  );
};

const getSize = (isMobile: boolean): number => {
  return isMobile ? 24 : 36;
};

const getLh = (isMobile: boolean): number => {
  return isMobile ? 1.2 : 1.1;
};
