import { CountryIso3166, LocaleIso6391 } from '@shared/gql/sdk';

type Currency = 'dkk' | 'gbp' | 'usd';
type IntlConfig = {
  locale: LocaleIso6391;
  country: CountryIso3166;
  currency: Currency;
  dateFormat: string;
};

export const CountryLocaleStoreKeys: Partial<Record<CountryIso3166, string>> = {
  [CountryIso3166.Dk]: 'locale_dk',
  [CountryIso3166.Gb]: 'locale_gb',
};

export const resolveCountry = () => {
  const { href } = window.location;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const paramCountry = params.get('country') as CountryIso3166;

  if (href.includes('/uk/')) {
    return CountryIso3166.Gb;
  } else if (href.includes('/dk/')) {
    return CountryIso3166.Dk;
  } else if (href.includes('/us/')) {
    return CountryIso3166.Us;
  } else return paramCountry || CountryIso3166.Gb;
};

export const resolveLocale = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const country = resolveCountry();
  const sessionLocale = sessionStorage.getItem(CountryLocaleStoreKeys[country]) as LocaleIso6391;
  const paramLocale = params.get('locale') as LocaleIso6391;
  const defaultLocale = country === CountryIso3166.Dk ? LocaleIso6391.Da : LocaleIso6391.En;

  return paramLocale || sessionLocale || defaultLocale;
};

export const resolveCurrency = () => {
  const country = resolveCountry();

  switch (country) {
    case CountryIso3166.Dk:
      return 'dkk';
    case CountryIso3166.Gb:
      return 'gbp';
    case CountryIso3166.Us:
      return 'usd';
    default:
      return 'gbp';
  }
};

const resolveDateFormat = () => {
  const locale = resolveLocale();
  const formatter = new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });

  const parts = formatter.formatToParts(new Date());
  const format = parts
    .map((part) => {
      switch (part.type) {
        case 'year':
          return 'YYYY';
        case 'month':
          return 'MM';
        case 'day':
          return 'DD';
        default:
          return part.value;
      }
    })
    .join('');
  return format;
};

export const resolveIntl = (): IntlConfig => {
  return {
    locale: resolveLocale(),
    country: resolveCountry(),
    currency: resolveCurrency(),
    dateFormat: resolveDateFormat(),
  };
};
