import { ProductCard } from '@components/ProductCard';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Group, Tabs } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { EligibilityType } from '@shared/gql/sdk';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

export const OurPrograms: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({
  context: { priceInfos },
  funnelApi,
}) => {
  const { isMobile } = useIsMobileViewport();

  const programPrices = useMemo(
    () =>
      [EligibilityType.CoachingAndMedication, EligibilityType.Coaching].map((et) =>
        priceInfos.find((pi) => pi.product.eligibilityType === et),
      ),
    [priceInfos],
  );

  const SelectionMap: Record<EligibilityType, ProductSelection> = {
    [EligibilityType.CoachingAndMedication]: ProductSelection.MedicationAndCoaching,
    [EligibilityType.Coaching]: ProductSelection.CoachingOnly,
  };

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'ourPrograms'} />} />
      {isMobile ? (
        <Tabs defaultValue={programPrices[0].id}>
          <Tabs.List>
            {programPrices.map((pp) => (
              <Tabs.Tab key={pp.id} value={pp.id} w={'50%'}>
                {pp.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {programPrices.map((pp) => (
            <Tabs.Panel value={pp.id} mt={'lg'}>
              <ProductCard
                key={pp.id}
                price={pp}
                productSelection={SelectionMap[pp.product.eligibilityType]}
                variant="program-top"
                hidePrice={true}
              />
            </Tabs.Panel>
          ))}
        </Tabs>
      ) : (
        <Group wrap="nowrap">
          {programPrices.map((pp) => (
            <ProductCard
              key={pp.id}
              price={pp}
              productSelection={SelectionMap[pp.product.eligibilityType]}
              variant="program-top"
              hidePrice={true}
            />
          ))}
        </Group>
      )}
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
