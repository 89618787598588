import { env } from '@env';
import { Stack, Stepper, Text } from '@mantine/core';
import { AddToCalendarButton } from '@shared/components/buttons/AddToCalendarButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { FeedbackLink } from '@shared/components/buttons/FeedbackLink';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { Icon } from '@shared/components/Icon';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { useTheme } from '@shared/theme';
import { FunctionComponent } from 'react';
import IFunnelContext, { FlowVariant } from 'src/FunnelContext';

import { t } from '@t';

export const Confirmation: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context }) => {
  const { serverRoot } = env || { serverRoot: 'https://app-joinembla.com' };
  const { flowVariant, appointmentId } = context;
  const isEmbeddedInApp = context.embedded;
  const { topText, title, lgText } = useConfirmationContent(flowVariant);
  const showDownloadButton = !isEmbeddedInApp;
  const showAppointmentInfo = flowVariant === 'booking';
  const showCallbackInfo = flowVariant === 'callback';

  return (
    <FullScreenContainer py="md">
      <Text ta="center" fw={700}>
        {topText}
      </Text>
      <StepTitle title={title} description={lgText} />

      {showAppointmentInfo || showCallbackInfo ? (
        <Stack>
          <Text fw={600} size="xl">
            {t('heresWhatToExpect')}
          </Text>

          <ConfirmationStepper
            email={context.userSession.email}
            isEmbeddedInApp={isEmbeddedInApp}
            flowVariant={flowVariant}
          />

          {isEmbeddedInApp ? (
            <Text ta="center" fw={400} size="lg">
              {t('weAppreciateYourTrustInEmbla')}
            </Text>
          ) : null}
        </Stack>
      ) : null}

      <Stack gap="md">
        {showDownloadButton && <DownloadButton />}
        {showAppointmentInfo && (
          <AddToCalendarButton
            iconPosition="right"
            variant={isEmbeddedInApp ? 'primary' : 'transparent'}
            isEmbeddedInApp={isEmbeddedInApp}
            serverRoot={serverRoot}
            appointmentId={appointmentId}
          />
        )}
        {isEmbeddedInApp && (
          <GrowingButton
            variant="transparent"
            buttonLabel={t('finish')}
            onClick={() => {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            }}
            leftSection={<Icon name="icon-fi-rr-check" size={20} />}
          />
        )}
        <FeedbackLink embedded={isEmbeddedInApp} source={context.source} />

        <PhoneImage width={280} />
      </Stack>
    </FullScreenContainer>
  );
};

const useConfirmationContent = (flowVariant: IFunnelContext['flowVariant'] = 'booking') => {
  let topText;
  let title;
  let lgText;

  switch (flowVariant) {
    case 'phone-already-registered':
      topText = t('alreadyRegistered');
      title = t('pleaseLoginInTheApp');
      break;
    case 'watch-list-for-higher-dosage':
      topText = t('thankYouForYourInterest');
      title = t('youWillBeNotifiedWhenHigerDosesAreAvailable');
      lgText = t('inTheMeantimeGetToKnowEmblaApp');
      break;
    case 'watch-list-screening-capacity':
      topText = t('thankYouForYourInterest');
      title = t('notifyMe.waitingForScreeningCapacity.title');
      lgText = t('notifyMe.description');
      break;
    case 'callback':
      topText = t('callbackBooked');
      title = t('thankYouWellTakeItFromHere');
      break;
    default:
      topText = t('bookingConfirmed');
      title = t('thankYouForBooking');
      break;
  }

  return {
    topText,
    title,
    lgText,
  };
};
const Step: FunctionComponent<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => {
  const { theme } = useTheme();
  return (
    <Stepper.Step
      styles={{
        stepIcon: {
          backgroundColor: 'transparent',
          borderWidth: 0,
        },

        verticalSeparator: {
          marginTop: -8,
          marginBottom: -8,
          borderWidth: 1,
          borderColor: theme.other.theme.funnel.text.secondary,
        },
      }}
      icon={icon}
      description={
        <Text size="lg" data-secondary data-secondary-font>
          {text}
        </Text>
      }
    />
  );
};

const ConfirmationStepper: FunctionComponent<{ email: string; isEmbeddedInApp: boolean; flowVariant: FlowVariant }> = ({
  flowVariant,
  email,
  isEmbeddedInApp,
}) => {
  let steps: React.ReactNode[] = [];

  if (flowVariant === 'booking') {
    steps = [
      <Step icon={<Icon name={'icon-fi-rr-envelope'} size={20} />} text={t('weSentAConfirmationEmailTo', { email })} />,
      <Step
        icon={<Icon name={'icon-fi-rr-video-camera-alt'} size={20} />}
        text={t('reminderNotification24HoursBeforeConsultation')}
      />,
      isEmbeddedInApp ? (
        <Step icon={<Icon name="icon-fi-rr-phone-call" size={20} />} text={t('weLookForwardToMeetingYou')} />
      ) : null,
    ].filter(Boolean);
  } else if (flowVariant === 'callback') {
    steps = [
      <Step icon={<Icon name={'icon-fi-rr-envelope'} size={20} />} text={t('weSentAConfirmationEmailTo', { email })} />,
      <Step icon={<Icon name={'icon-fi-rr-phone-call'} size={20} />} text={t('weLookForwardToTalkingToYou')} />,
    ];
  }
  return (
    <Stepper orientation="vertical" active={0}>
      {steps}
    </Stepper>
  );
};
