import { Group, MantineStyleProp, Stack, Text } from '@mantine/core';
import { EmblaIconName, Icon } from '@shared/components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/FunnelContext';

interface Props {
  selectedProduct: ProductSelection;
}

export const Benefits: React.FC<Props> = ({ selectedProduct }) => {
  const { t } = useTranslation();
  const showShipping = selectedProduct === ProductSelection.MedicationAndCoaching;

  return (
    <Group align="flex-start">
      <Benefit text={t('benefit.freeCancellation')} icon={'icon-fi-rr-time-delete'} />
      <Benefit text={t('benefit.fullRefundIfIneligible')} icon={'icon-fi-rr-money-bill-wave-alt'} />
      {showShipping && <Benefit text={t('benefit.trackedDelivery')} icon={'icon-fi-rr-journey'} />}
    </Group>
  );
};

const Benefit: React.FC<{
  text: string;
  icon: EmblaIconName;
  style?: MantineStyleProp;
}> = ({ text, icon }) => {
  return (
    <Stack flex={1} gap="sm" align="center" justify="center" my={'lg'}>
      <Icon name={icon} size={20} data-secondary />
      <Text fw={400} size="sm" ta="center" data-secondary>
        {text}
      </Text>
    </Stack>
  );
};
