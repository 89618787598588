import { Feedback } from '@pages/feedback/Feedback';
import { FeedbackThankYou } from '@pages/feedback/FeedbackThankYou';
import api from '@shared/api';
import { funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { UserSubmitFeedbackMutationVariables } from '@shared/gql/sdk';
import IFunnelContext from 'src/FunnelContext';

export const createFeedbackFunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    config: {
      feedback: {
        feedback: funnelPage(Feedback, {
          actions: {
            submitFeedback: async (data: UserSubmitFeedbackMutationVariables[]) => {
              await Promise.all(
                data.map((d) => {
                  return api.UserSubmitFeedback(d);
                }),
              );
            },
          },
        }),
        thank_you: funnelPage(FeedbackThankYou, {
          terminal: true,
        }),
      },
    },

    defaultLoader: {
      component: () => null,
      progressBar: true,
      minimumDurationSeconds: 0.5,
    },
    initialContext,
    defaultPageWidth: 480,
  });
