import { ProductCard } from '@components/ProductCard';
import { Stack, Text, UnstyledButton } from '@mantine/core';
import { FinancialPrice } from '@shared/gql/sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/FunnelContext';

interface Props {
  selectedProduct: ProductSelection;
  price: FinancialPrice;
  selected: boolean;
  onClick?: () => void;
  compact?: boolean;
  highlightSelected?: boolean;
  label?: string;
}

const YourRecommendedMembership: React.FC<Props> = ({
  selectedProduct,
  price,
  selected,
  onClick,
  compact,
  highlightSelected,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <UnstyledButton onClick={onClick}>
      <Stack gap={0}>
        <Text py="xs" fw="bold">
          {label || t('yourRecommendedMembership')}
        </Text>

        <ProductCard
          compact={compact}
          price={price}
          productSelection={selectedProduct}
          highlightSelected={highlightSelected}
          selected={selected}
        />
      </Stack>
    </UnstyledButton>
  );
};

export { YourRecommendedMembership };
