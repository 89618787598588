import { Radio, Stack } from '@mantine/core';
import { RadioCard } from '@shared/components/RadioCard';
import { SexAssignedAtBirth } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

export const SexAssignedSelection: React.FC<{
  sexAssignedAtBirth: SexAssignedAtBirth;
  setSexAssignedAtBirth: React.Dispatch<React.SetStateAction<SexAssignedAtBirth>>;
  showPreferNotToSay?: boolean;
}> = ({ sexAssignedAtBirth, setSexAssignedAtBirth, showPreferNotToSay }) => {
  const { t } = useTranslation();

  return (
    <Radio.Group value={sexAssignedAtBirth}>
      <Stack gap="sm">
        <RadioCard
          checked={sexAssignedAtBirth === SexAssignedAtBirth.Male}
          value={SexAssignedAtBirth.Male}
          label={t('male')}
          select={() => setSexAssignedAtBirth(SexAssignedAtBirth.Male)}
        />
        <RadioCard
          checked={sexAssignedAtBirth === SexAssignedAtBirth.Female}
          value={SexAssignedAtBirth.Female}
          label={t('female')}
          select={() => setSexAssignedAtBirth(SexAssignedAtBirth.Female)}
        />
        {showPreferNotToSay && (
          <RadioCard
            checked={sexAssignedAtBirth === SexAssignedAtBirth.PreferNotToSay}
            value={SexAssignedAtBirth.PreferNotToSay}
            label={t('preferNotToSay')}
            select={() => setSexAssignedAtBirth(SexAssignedAtBirth.PreferNotToSay)}
          />
        )}
      </Stack>
    </Radio.Group>
  );
};
