import { Button, Image, Stack } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaInnerContainer } from '@shared/components/EmblaInnerContainer';
import { EmblaTextInput } from '@shared/components/EmblaTextInput';
import { EmblaTopContainer } from '@shared/components/EmblaTopContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { PartnerInfo } from '@shared/gql/sdk';
import { usePartnerSession } from '@util/helpers';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FunnelPartnerSignupUS } from './FunnelPartnerSignupUS';

const PartnerWelcome: FunctionComponent<{ partnerInfo: PartnerInfo }> = ({ partnerInfo }) => {
  const { t } = useTranslation();

  return (
    <EmblaTopContainer>
      <EmblaInnerContainer maw={480}>
        <FullScreenContainer mt={120}>
          <Stack gap="xl">
            {partnerInfo?.logoImageUrl && (
              <Image
                src={partnerInfo?.logoImageUrl}
                fit="contain"
                mah={150}
                alt={partnerInfo?.name}
                style={{ maxWidth: '100%', height: 'auto', alignSelf: 'center' }} // Ensure the image does not exceed the screen width
              />
            )}
            <StepTitle title={t('partner.entry.title')} description={t('partner.entry.description')} />
            <Stack align="center">
              <Button
                component="a"
                href="/dk/partner/signup"
                style={{ width: '100%' }} // Make the button grow to fit the width
                maw={400}
              >
                {t('btn.partner.signup')}
              </Button>
              <Button
                component="a"
                href="/dk/partner/checkup"
                variant="outline"
                style={{ width: '100%' }} // Make the button grow to fit the width
                maw={400}
              >
                {t('btn.partner.checkup')}
              </Button>
            </Stack>
          </Stack>
        </FullScreenContainer>
      </EmblaInnerContainer>
    </EmblaTopContainer>
  );
};

const PartnerEnterCode: FunctionComponent<{ invalidCode?: boolean }> = ({ invalidCode }) => {
  const { t } = useTranslation();
  const { partnerCode, setPartnerCode } = usePartnerSession(true);
  const [codeInput, setCodeInput] = useState<string>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (partnerCode) {
      setCodeInput(partnerCode);
    }
  }, [partnerCode]);

  useEffect(() => {
    if (invalidCode) {
      setError(t('invalidCode'));
    } else {
      setError(undefined);
    }
  }, [invalidCode, t]);

  return (
    <EmblaTopContainer>
      <EmblaInnerContainer maw={480}>
        <FullScreenContainer>
          <Stack gap="md" mt={120} align="center">
            <StepTitle title={t('partner.entry.title')} description={t('partner.entry.description')} />
            <EmblaTextInput
              style={{ width: '100%' }}
              maw={400}
              error={error}
              value={codeInput}
              withAsterisk
              placeholder={t('partnerId')}
              onChange={(e) => {
                setError(undefined);
                setCodeInput(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setPartnerCode(codeInput);
                }
              }}
            />
          </Stack>
          <NextButton onClick={() => setPartnerCode(codeInput)} disabled={!codeInput} />
        </FullScreenContainer>
      </EmblaInnerContainer>
    </EmblaTopContainer>
  );
};

export const PartnerEntry: FunctionComponent = () => {
  const { partnerCode, partnerInfo, invalidCode } = usePartnerSession(true);

  const loading = partnerCode && !partnerInfo && !invalidCode;
  const ready = partnerCode && partnerInfo;

  if (loading) return null;
  if (ready) return <PartnerWelcome partnerInfo={partnerInfo} />;
  else return <PartnerEnterCode invalidCode={invalidCode} />;
};

export const PartnerEntryUS: FunctionComponent = () => {
  const { partnerCode, partnerInfo, invalidCode } = usePartnerSession();

  const loading = partnerCode && !partnerInfo && !invalidCode;
  const ready = partnerCode && partnerInfo;

  if (loading) return null;
  if (ready) return <FunnelPartnerSignupUS />;
  else return <PartnerEnterCode invalidCode={invalidCode} />;
};
