import { createFeedbackFunnelConfig } from '@configs/feedback/config';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled, getUrlParams } from '@shared/helpers';
import { useRef } from 'react';
import './FunnelUK.css';

interface IFeedbackContext {
  source: 'APP' | 'SIGNUP-FUNNEL' | 'PHARMACY';
}

interface IFeedbackSession {
  context: IFeedbackContext;
}

const getFeedbackConfig = (session: IFeedbackSession) => {
  return createFeedbackFunnelConfig(session.context);
};

const Feedback = () => {
  const funnelCallbackConfig = useRef(
    getFeedbackConfig({ context: { source: getUrlParams().get('source') as IFeedbackContext['source'] } }),
  );
  return <Funnel name="v3-feedback-funnel" debug={debugEnabled()} options={funnelCallbackConfig.current} />;
};

export default Feedback;
