import { lightTheme } from '@joinembla/theme';
import {
  CSSVariablesResolver,
  MantineProvider,
  MantineTheme,
  colorsTuple,
  createTheme,
  mergeMantineTheme,
} from '@mantine/core';
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import classes from './theme.module.css';
import buttonClasses from './themed-css/Button.module.css';
import cardClasses from './themed-css/Card.module.css';
import checkboxClasses from './themed-css/Checkbox.module.css';
import indicatorClasses from './themed-css/Indicator.module.css';
import lineChartClasses from './themed-css/LineChart.module.css';
import numberInputClasses from './themed-css/NumberInput.module.css';
import paperClasses from './themed-css/Paper.module.css';
import progressClasses from './themed-css/Progress.module.css';
import segmentedControlClasses from './themed-css/SegmentedControl.module.css';
import tabClasses from './themed-css/Tab.module.css';
import textClasses from './themed-css/Text.module.css';
import themeIconClasses from './themed-css/ThemeIcon.module.css';
import { getEmblaTheme, getMantineThemeAdditions, getNestedStringMap, loadFontAssets, newColors } from './themeUtil';

export const fontSizes = {
  xxs: '10px',
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '30px',
};

newColors.primary = colorsTuple(lightTheme.funnel.text.primary);
newColors.secondary = colorsTuple(lightTheme.funnel.text.secondary);
newColors.tertiary = colorsTuple(lightTheme.funnel.text.tertiary);
export const baseTheme = createTheme({
  lineHeights: { xs: '1.3', sm: '1.3', md: '1.3', lg: '1.3', xl: '1.4' },

  components: {
    Alert: {
      defaultProps: {
        radius: 'lg',
      },
    },
    Progress: {
      classNames: progressClasses,
    },
    LineChart: {
      classNames: lineChartClasses,
    },
    InputWrapper: {
      classNames: {
        error: classes.inputError,
      },
    },
    Input: {
      classNames: { section: classes.section },
    },
    ThemeIcon: {
      classNames: themeIconClasses,
    },
    TextInput: {
      classNames: {
        input: classes.input,
      },
      defaultProps: {
        size: 'lg',
        py: 'xs',
      },
    },
    Tabs: {
      classNames: tabClasses,
    },
    NumberInput: {
      classNames: {
        input: numberInputClasses.input,
      },
      defaultProps: {
        size: 'lg',
        py: 'xs',
      },
    },
    Checkbox: {
      classNames: checkboxClasses,
      defaultProps: {
        fontSize: 'lg',
        size: 'md',
        radius: 'xl',
      },
    },
    Card: {
      classNames: cardClasses,
    },
    Paper: {
      classNames: paperClasses,
    },
    Radio: {
      classNames: checkboxClasses,
      defaultProps: {
        fontSize: 'lg',
        size: 'md',
      },
    },
    Button: {
      classNames: buttonClasses,
      defaultProps: {
        radius: 0,
        size: 'lg',
      },
    },
    Indicator: {
      classNames: indicatorClasses,
    },

    Select: {
      classNames: {
        input: classes.input,
        item: classes.selectItem,
      },
    },
    Text: {
      classNames: textClasses,
    },
    Title: {
      classNames: textClasses,
    },
    Anchor: {
      classNames: textClasses,
    },
    SegmentedControl: {
      classNames: segmentedControlClasses,
    },
    Tooltip: {
      defaultProps: {
        withArrow: true,
        arrowSize: 7,
        py: 'sm',
        px: 'md',
        multiline: true,
        w: 250,
        events: {
          hover: true,
          touch: true,
        },
      },
    },
  },
  colors: newColors,
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  fontSizes: fontSizes,
  headings: {
    fontWeight: '600',
    sizes: {
      h1: {
        fontSize: '56px', // lg
        lineHeight: '61px',
      },
      h2: {
        fontSize: '36px', // md
      },
      h3: {
        fontSize: '30px', // sm
      },
      h4: {
        fontSize: '24px', // xs
      },
      h5: {
        fontSize: '20px', // xxs
      },
    },
  },
  defaultRadius: 8,
}) as MantineTheme;

// is async but should be pretty instant as all fonts are bundled and not loaded over network
// loadFontAssets(theme.other.theme as EmblaTheme);

export const cssVariableResolver: CSSVariablesResolver = (theme) => {
  const variables = getNestedStringMap(theme.other.theme, '--embla-theme');

  return {
    variables: {
      ...variables,
      '--text-color': 'var(--embla-theme-screen-primary)',
    },
    dark: {},
    light: {},
  };
};

interface IThemeContext {
  theme: MantineTheme;
}

const ThemeContext = React.createContext<IThemeContext>({
  theme: null,
});

export const ThemeContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const mantineTheme = useMemo(() => mergeMantineTheme(baseTheme, getMantineThemeAdditions()), []);

  useEffect(() => {
    loadFontAssets(getEmblaTheme());
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme: mantineTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  return context;
};

export const ThemedMantineProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const themeContext = useTheme();

  return (
    <MantineProvider theme={themeContext.theme} cssVariablesResolver={cssVariableResolver}>
      {children}
    </MantineProvider>
  );
};
