import projectionGraphCoachingAndMedication from '@assets/weight-loss-prediction-v3.svg';
import { Card, Group, Image, List, MantineSize, Stack, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { StepTitle } from '@shared/components/StepTitle';
import { UnitSystem } from '@shared/gql/sdk';
import { useUnitConversion } from '@shared/unitConversion';
import dayjs from 'dayjs';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const CoachingAndMedication: FunctionComponent<{
  weight: number;
  unitSystem: UnitSystem;
}> = ({ weight, unitSystem }) => {
  const { targetWeight } = useWeightPrediction(weight);
  const { format } = useUnitConversion(unitSystem);
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="lg" w={'100%'}>
      <StepTitle
        title={t('youCanReach', {
          weight: format(targetWeight),
        })}
        description={t('yourWeightLossJourney')}
      />
      <Stack maw={430} w="100%" justify="space-between" flex={1}>
        <WeightProjectionGraphCoachingAndMedication unitSystem={unitSystem} weight={weight} />
        <List withPadding style={{ width: '100%' }}>
          <List.Item>
            <Text fs={'italic'} size={'sm'}>
              {t('aboveIsSimplifiedProjection')}
            </Text>
          </List.Item>
        </List>
      </Stack>
    </Stack>
  );
};

const WeightProjectionGraphCoachingAndMedication: FunctionComponent<{
  unitSystem: UnitSystem;
  weight: number;
}> = ({ unitSystem, weight }) => {
  const { targetWeight } = useWeightPrediction(weight);
  const { format } = useUnitConversion(unitSystem);
  const { ref: markerRef, width: markerWidth } = useElementSize<HTMLDivElement>();

  return (
    <Group
      w="100%"
      wrap="nowrap"
      align="stretch"
      gap={'sm'}
      mih={400}
      justify="center"
      style={{ position: 'relative', width: '100vw' }}
    >
      <div
        style={{
          display: 'flex',
          paddingTop: 10,
          paddingBottom: 10,
          position: 'relative',
          flex: 1,
        }}
      >
        <Image
          w="100%"
          style={{
            flex: 1,
            overflow: 'visible',
          }}
          src={projectionGraphCoachingAndMedication}
        />
      </div>
      {targetWeight && (
        <div
          ref={markerRef}
          style={{
            position: 'absolute',
            left: `calc(77.5% - ${Math.round(markerWidth / 2)}px)`,
            bottom: '68%',
          }}
        >
          <WeightBadge size="xl" content={format(targetWeight)} />
        </div>
      )}
    </Group>
  );
};

const WeightBadge: FunctionComponent<{
  content: string;
  size: MantineSize;
}> = ({ content, size }) => {
  return (
    <Card radius="lg" fw={'bold'} py={0} px={12} shadow="md">
      <Text fw={600} size={size} data-secondary>
        {content}
      </Text>
    </Card>
  );
};

const useWeightPrediction = (weight: number) => {
  const targetDate = useMemo(() => dayjs().add(6, 'months'), []);
  const targetWeight = weight ? Math.round(weight * 0.869) : undefined;
  const targetWeightLoss = weight ? Math.round(weight - targetWeight) : undefined;

  return {
    currentWeight: weight,
    targetWeight,
    targetDate,
    targetWeightLoss,
  };
};
