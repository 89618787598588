import imgAnneVibe from '@assets/images/employees/anneVibe.png';
import imgSteph from '@assets/images/employees/steph.png';
import { Image, Stack, Text } from '@mantine/core';
import { QuoteComponent } from '@shared/components/QuoteComponent';
import { StepTitle } from '@shared/components/StepTitle';
import { CountryIso3166 } from '@shared/gql/sdk';
import { FunctionComponent } from 'react';

import { useIndexStepper } from '../hooks/useIndexStepper';

import { t } from '@t';

type Quote = {
  quote: string;
  trustPilot?: boolean;
  reference?: string;
  time?: number;
};

type EmployeeData = {
  imageSrc: string;
  name: string;
  title: string;
  quotes: Quote[];
  text?: string;
  description?: string;
};

type EmployeeBase = {
  imageSrc: string;
  name: string;
  title: string;
};

const steph = (): EmployeeBase => ({
  imageSrc: imgSteph,
  name: 'Steph Gregory',
  title: t('coach'),
});

const andrea = (): EmployeeBase => ({
  // imageSrc: imgAndrea,
  // name: 'Andrea Robinson',
  imageSrc: imgSteph,
  name: 'Steph Gregory',
  title: t('coach'),
});

const anneVibe = (): EmployeeBase => ({
  imageSrc: imgAnneVibe,
  name: 'Anne vibe',
  title: t('coach'),
});

const buildEmployee = (
  base: () => EmployeeBase,
  options: { text?: string; description?: string; quotes?: Quote[] },
): EmployeeData => ({
  ...base(),
  ...options,
  quotes: options.quotes ?? [],
});

const EmployeeWithTitleAndQuote: FunctionComponent<{
  getEmployee: () => EmployeeData;
}> = ({ getEmployee }) => {
  const { quotes, imageSrc, name, title, text, description } = getEmployee();
  const { currentIndex, currentStepTime: stepTime } = useIndexStepper(quotes.map((q) => q.time));

  return (
    <Stack align="center" mih={350} gap={'md'}>
      <Image src={imageSrc} h={148} w={148} radius={'md'} />
      {text ? <StepTitle title={text} description={description} /> : null}
      {quotes.map((q, i) => (
        <QuoteComponent key={`qoute+${i}`} {...q} isMounted={currentIndex === i} totalTime={stepTime} />
      ))}
      <Stack gap={'xs'}>
        <Text ta="center" fw={600} size={'lg'}>
          {name}
        </Text>
        <Text ta="center" data-tertiary fw={400} size={'lg'}>
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};

const buildLoader = (
  emp: () => EmployeeBase,
  options: {
    text?: string;
    description?: string;
    quotes?: Quote[];
  },
  minimumDurationSeconds: number,
) => {
  return {
    component: EmployeeWithTitleAndQuote.bind(null, {
      getEmployee: () => buildEmployee(emp, options),
    }),
    minimumDurationSeconds,
    progressBar: true,
  };
};

export const loaders = {
  [CountryIso3166.Dk]: {
    youQualify: buildLoader(
      anneVibe,
      {
        quotes: [
          { quote: t('qGreatWeAreReviewingAnswers'), time: 3000 },
          { quote: t('qThisIsTheCourseWeOfferToYou'), time: 3000 },
        ],
      },
      6,
    ),
    comorbidities: buildLoader(
      anneVibe,
      {
        quotes: [{ quote: t('nextStepIsToUnderstand'), time: 3000 }],
      },
      3,
    ),
  },
  [CountryIso3166.Us]: {
    healthConditions: buildLoader(
      andrea,
      {
        quotes: [{ quote: t('qWeNeedToKnowRelevantHealthConditions'), time: 3000 }],
      },
      3,
    ),
    weAreGeneratingYourRecommendations: buildLoader(
      andrea,
      {
        text: t('weAreGeneratingYourRecommendationsNow'),
        description: t('youDontHaveToDoAnything'),
      },
      3,
    ),
    weAreRewiewingYourAnswers: buildLoader(
      andrea,
      {
        text: t('weAreReviewingYourAnswers'),
        description: t('clinicianWillAssess'),
      },
      3,
    ),
  },
  [CountryIso3166.Gb]: {
    youQualify: buildLoader(
      steph,
      {
        quotes: [{ quote: t('qGreatYouQualify'), time: 3000 }],
      },
      3,
    ),
    weAreGeneratingYourRecommendationsCoaching: buildLoader(
      steph,
      {
        text: t('weAreGeneratingYourRecommendationsNow'),
        description: t('youDontHaveToDoAnything'),
      },
      3,
    ),
    weAreGeneratingYourRecommendationsCoachingAndMedication: buildLoader(
      steph,
      {
        text: t('weAreGeneratingYourRecommendationsNow'),
        description: t('clinicianWillAssess'),
      },
      3,
    ),
  },
};

export const CoachName: FunctionComponent<{ name: 'Anne Vibe' }> = ({ name }) => {
  return (
    <Stack gap={'xs'}>
      <Text ta="center" fw={600} size={'lg'}>
        {name}
      </Text>
      <Text ta="center" data-tertiary fw={400} size={'lg'}>
        {t('coach')}
      </Text>
    </Stack>
  );
};
