import { Affix, Group, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Icon } from '@shared/components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmblaLink } from './EmblaLink';

export const RecapthchaBanner: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const { width } = useViewportSize();

  const banner = (
    <Group
      justify={'center'}
      align={'center'}
      style={{
        display: 'flex',
        borderRadius: 32,
        border: 'solid 2px white',
        margin: '0 auto',
      }}
      px={'md'}
      py={'md'}
      gap={'sm'}
    >
      <Icon name={'icon-fi-rr-shield'} size={24} />
      <Stack align="flex-start" justify="center" gap={0}>
        <Text size={'xxs'}>{t('thisSiteIsProtectedByRecaptchaEnterprise')}</Text>
        <Group flex={0} align="center" justify="center" gap={4}>
          <Text size={'xxs'}>{t('theGoogle')}</Text>
          <EmblaLink href="https://policies.google.com/privacy" size="xxs">
            {t('recaptchaPrivacyPolicy')}
          </EmblaLink>
          <Text size={'xxs'}>{t('and')}</Text>
          <EmblaLink href="https://policies.google.com/terms" size="xxs">
            {t('recaptchaTermsOfService')}
          </EmblaLink>
          <Text size={'xxs'}>{t('apply')}</Text>
        </Group>
      </Stack>
    </Group>
  );

  if (width > 1350) {
    return <Affix position={{ bottom: 20, left: 20 }}>{banner}</Affix>;
  }

  return banner;
};
