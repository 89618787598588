import requestCallbackImage from '@assets/images/request-callback.png';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Checkbox, Group, Image, Paper, Stack, Text, UnstyledButton } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaCheckbox } from '@shared/components/EmblaCheckbox';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { AddressForm } from './AddressForm';
import { Benefits } from './Benefits';
import { MembershipSummary } from './MembershipSummary';
import { SwitchProgram } from './SwitchProgram';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const CheckoutOrCallback: FunnelPageComponent<
  {
    address: Address;
    didSelectCallback: boolean;
    confirmation: boolean;
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({
  data: { address, didSelectCallback: initialDidSelectCallback, confirmation: initialConfirmation },
  context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const shippingAddress = useRef(address);
  const addressFormRef = useRef<{ validate: () => boolean }>();

  const [didSelectCallback, setDidSelectCallback] = useState(
    initialDidSelectCallback !== undefined ? initialDidSelectCallback : false,
  );
  const [_confirmation, setConfirmation] = useState(initialConfirmation);

  const confirmation = didSelectCallback || _confirmation;

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'yourFutureSelfWillThankYou'} />} />
      <Stack gap="lg">
        <Stack gap={'sm'}>
          <YourRecommendedMembership
            price={price}
            selectedProduct={selectedProduct}
            selected={!didSelectCallback}
            onClick={() => setDidSelectCallback(false)}
            compact
            highlightSelected
          />

          <RequestCallbackCard selected={didSelectCallback} onClick={() => setDidSelectCallback(true)} />

          {eligibleFor === ProductSelection.MedicationAndCoaching && (
            <SwitchProgram
              productChanged={true}
              selectedProduct={selectedProduct}
              onClick={() => {
                funnelApi.next({
                  selectedProduct:
                    selectedProduct == ProductSelection.CoachingOnly
                      ? ProductSelection.MedicationAndCoaching
                      : ProductSelection.CoachingOnly,
                  productChanged: true,
                  address: shippingAddress.current,
                  didSelectCallback,
                  confirmation,
                });
              }}
            />
          )}
        </Stack>

        {!didSelectCallback && selectedProduct === ProductSelection.MedicationAndCoaching && (
          <>
            <AddressForm
              ref={addressFormRef}
              label={t('deliveryAddress')}
              address={address}
              onChange={(changedAddress) => {
                shippingAddress.current = changedAddress;
              }}
            />
            <MembershipSummary price={price} />
          </>
        )}

        <TrustBoxMicroTrustScore />
        <Benefits selectedProduct={selectedProduct} />
        {!didSelectCallback && (
          <EmblaCheckbox
            className="stripe-checkbox"
            label={t('checkoutConfirmationDescription')}
            checked={confirmation}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => {
              setConfirmation(e.target.checked);
            }}
          />
        )}
      </Stack>
      <BottomScreenContainer>
        {didSelectCallback ? (
          <NextButton
            onClick={() =>
              funnelApi.next({ selectedProduct, address: shippingAddress.current, didSelectCallback, confirmation })
            }
            label={t('callMeBack')}
            disabled={!confirmation}
          />
        ) : (
          <NextButton
            onClick={() => {
              const addressIsValid = addressFormRef?.current?.validate();
              if (selectedProduct === ProductSelection.MedicationAndCoaching ? addressIsValid : true) {
                funnelApi.next({
                  selectedProduct,
                  address: shippingAddress.current,
                  didSelectCallback,
                  confirmation,
                });
              }
            }}
            label={t('Checkout')}
            disabled={!confirmation}
          />
        )}

        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const RequestCallbackCard = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileViewport();
  const stackRef = useRef(null);

  return (
    <UnstyledButton onClick={onClick}>
      <Paper data-selected={selected} p={'md'}>
        <Group align="center" justify="center" wrap="nowrap">
          <Image src={requestCallbackImage} w={62} h={74} />
          <Stack ref={stackRef} style={{ flex: 1 }} justify="flex-start" gap={0}>
            <Group justify="space-between">
              <Text fw={isMobile ? 600 : 400} lh={1.1} size={isMobile ? '18px' : '24px'}>
                {t('requestACallback')}
              </Text>
              <Checkbox checked={selected} />
            </Group>
            <Text fw={400} data-secondary>
              <Trans
                i18nKey={'gotQuestionsWellCallYouToProvideAnswers'}
                components={{
                  highlight: <span style={{ fontWeight: 600 }} />,
                }}
              />
            </Text>
          </Stack>
        </Group>
      </Paper>
    </UnstyledButton>
  );
};
