import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Stack, StackProps } from '@mantine/core';
import React, { FunctionComponent } from 'react';

export const FullScreenContainer: FunctionComponent<StackProps> = ({ children, ...props }) => {
  const { isMobile } = useIsMobileViewport();

  return (
    <Stack h={isMobile ? '100%' : undefined} gap={isMobile ? 24 : 48} {...props}>
      {children}
    </Stack>
  );
};
