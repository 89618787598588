/* eslint-disable @typescript-eslint/no-explicit-any */
export type SetValue<T> = (value: T | ((value: T) => T)) => void;

interface IFunnelState {
  hasPreviousPage: boolean;
}
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type GlobalHandlerProps<T extends FunnelEngineConfig> = {
  page: FunnelRoutes<T>;
  data: FunnelEngineData<T>;
  context: FunnelEngineContext<T>;
  isReplay: boolean;
};
type GlobalSectionHandlerProps<T extends FunnelEngineConfig> = {
  section: keyof T;
  page: FunnelRoutes<T>;
  data: FunnelEngineData<T>;
  context: FunnelEngineContext<T>;
  isReplay: boolean;
};

export interface IFunnelEngineHookHandlers<T extends FunnelEngineConfig> {
  onComplete?: FunnelHandler<T>;
  onNavigate?: (props: {
    previousPage: FunnelRoutes<T>;
    page: FunnelRoutes<T>;
    context: FunnelEngineContext<T>;
    data: FunnelEngineData<T>;
  }) => void | Promise<void>;
  onPageExit?: FunnelHandler<T>;
  onPageEntry?: FunnelHandler<T>;
  onSectionEntry?: FunnelSectionHandler<T>;
}

export interface IFunnelEngineHookOptions<T extends FunnelEngineConfig> {
  config: T;
  defaultPageWidth?: number;
  initialContext: FunnelEngineContext<T>;
  initialData?: DeepPartial<FunnelEngineData<T>>;
  initialRoute?: FunnelRoutes<T>;
  defaultLoader: IFunnelLoaderConfig;
  initialLoader?: IFunnelLoaderConfig;
  routingRules?: FunnelRoutingRules<T>;
  disableHashRouting?: boolean;
  replay?: boolean;
  handlers?: IFunnelEngineHookHandlers<T>;
  noMinimumLoadingTime?: boolean;
}

type AddLastParameter<T extends any[], TContext> = [...args: T, props: TContext];

export type ContextFunnelActions<T extends FunnelPageActions, TContext> = {
  [K in keyof T]: (...args: AddLastParameter<Parameters<T[K]>, TContext>) => ReturnType<T[K]>;
};

export interface IFunnelApi<T, TContext extends object, TActions extends FunnelPageActions, TErrors extends string> {
  update: (data: Partial<T>) => void;
  next: (data: T) => void;
  back: () => void;
  setContext: (context: TContext) => void;
  updateContext: (context: Partial<TContext>) => void;
  state: IFunnelState;
  actions: TActions;
  error?: TErrors;
}

export type FunnelPageActions<TInput extends [] = any, TOutput = any> = {
  [key: string]: (...args: TInput) => Promise<TOutput>;
};

export interface IFunnelNavigation<T extends FunnelEngineConfig> {
  currentRoute: FunnelRoute<T>;
  navigationStack: FunnelRoute<T>[];
}

export interface IFunnelPageProps<
  T,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string,
> {
  funnelApi: IFunnelApi<T, TContext, TActions, TErrors>;
  data: T;
  context: TContext;
}

export type FunnelPageComponent<
  T extends { [key: string]: unknown },
  TContext extends object = undefined,
  TErrors extends string = undefined,
  TActions extends FunnelPageActions = undefined,
> = React.FunctionComponent<IFunnelPageProps<T, TContext, TActions, TErrors>>;

export type FunnelPageData<T> = T extends React.ComponentType<infer TProps>
  ? TProps extends IFunnelPageProps<infer TData, any, any, any>
    ? TData
    : never
  : never;

type ImmediateOrSyncFn<FInput extends unknown[], T> = T | ((...args: FInput) => T);

export type FunnelPageConfiguration<
  TData extends Record<string, unknown>,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string,
> = {
  onEntry?: (props: IFunnelPageProps<TData, TContext, TActions, TErrors>) => Promise<TErrors | void>;
  component: FunnelPageComponent<TData, TContext, TErrors, TActions>;
  onExit?: (props: IFunnelPageProps<TData, TContext, TActions, TErrors>) => Promise<TErrors | void>;
  loader?: ImmediateOrSyncFn<[props: { context: TContext; data: TData }], Partial<IFunnelLoaderConfig>>;
  noContainer?: boolean;
  noSectionDisplay?: boolean;
  pageWidth?: number;
  terminal?: boolean;
  actions: ContextFunnelActions<TActions, IFunnelPageProps<TData, TContext, TActions, TErrors>>;
};

type NoUnion<T extends object, U = T> = T extends U ? ([U] extends [T] ? T : never) : never;
type NoUndefined<T> = T extends undefined ? never : T;

export interface FunnelEngineConfig<
  TData extends Record<string, unknown> = any,
  TContext extends object = any,
  TActions extends FunnelPageActions = any,
  TErrors extends string = any,
> {
  [key: string]: {
    [key: string]: FunnelPageConfiguration<TData, TContext, TActions, TErrors>;
  };
}

export type FunnelEngineContext<T> = NoUnion<T extends FunnelEngineConfig<any, infer C, any> ? NoUndefined<C> : never>;

type FunnelRouteWithError<T extends FunnelEngineConfig> = {
  [S in keyof T]: {
    [P in keyof T[S]]: {
      route: RouteString<T, S, P>;
      error: FunnelPageErrors<T[S][P]['component']>;
    };
  }[keyof T[S]];
}[keyof T];

type FunnelRoutingRuleResult<T extends FunnelEngineConfig> = FunnelRoutes<T> | FunnelRouteWithError<T>;

export type FunnelRoutingRules<T extends FunnelEngineConfig> = {
  [S in keyof T]?: {
    [P in keyof T[S]]?: (
      props: Omit<
        IFunnelPageProps<FunnelPageData<T[S][P]['component']>, FunnelEngineContext<T>, never, never>,
        'funnelApi'
      > & { error?: FunnelPageErrors<T[S][P]['component']> },
    ) => FunnelRoutingRuleResult<T> | undefined;
  };
};

type RouteString<T extends FunnelEngineConfig, S extends keyof T, P extends keyof T[S]> = S extends string
  ? P extends string
    ? `${S}/${P}`
    : never
  : never;

export type FunnelRoutes<T extends FunnelEngineConfig> = {
  [S in keyof T]: RouteString<T, S, keyof T[S]>;
}[keyof T];

export type FunnelPageErrors<T> = T extends FunnelPageComponent<any, any, infer TErrors, any> ? TErrors : never;

export type FunnelPageContext<T> = T extends FunnelPageComponent<any, infer TContext> ? TContext : never;

export type FunnelEngineActions<T> = T extends FunnelEngineConfig<any, any, infer TActions> ? TActions : never;

export type FunnelEngineErrors<T extends FunnelEngineConfig> = FunnelPageErrors<
  T[keyof T][keyof T[keyof T]]['component']
>;

export type FunnelEngineData<T extends FunnelEngineConfig<any, FunnelEngineContext<T>, any>> = {
  [K in keyof T]: { [P in keyof T[K]]: FunnelPageData<T[K][P]['component']> };
};

export enum NavigationDirection {
  Back = -1,
  Next = 1,
}

export interface IFunnelLoaderConfig {
  component: React.FunctionComponent;
  minimumDurationSeconds?: number;
  progressBar?: boolean;
  offsetX?: number;
  offsetY?: number;
}

export interface FunnelRoute<
  T extends FunnelEngineConfig,
  S extends keyof T = keyof T,
  P extends keyof T[S] = keyof T[S],
> {
  section: S;
  page: P;
}

export type FunnelPageHandler<T> = T extends FunnelPageComponent<
  infer TData,
  infer TContext,
  infer TErrors,
  infer TActions
>
  ? (props: IFunnelPageProps<TData, TContext, TActions, TErrors>) => Promise<void | TErrors>
  : never;

export type FunnelHandler<T extends FunnelEngineConfig> = (props: GlobalHandlerProps<T>) => void | Promise<void>;
export type FunnelSectionHandler<T extends FunnelEngineConfig> = (
  props: GlobalSectionHandlerProps<T>,
) => void | Promise<void>;

export type FunnelDebugApi<T extends FunnelEngineConfig> = {
  goForwardTo: (route: FunnelRoute<T>) => void;
  resetFunnel: () => void;
  executePageEntry: (page: FunnelRoute<T>) => Promise<void>;
  executePageExit: (page: FunnelRoute<T>) => Promise<void>;
  toggleLoader: (page: FunnelRoute<T>) => Promise<void>;
};
