import { Group, Stack, Text, Tooltip } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import { Receipt } from '@shared/components/Receipt';
import { ReceiptUS } from '@shared/components/ReceiptUS';
import { CountryIso3166, EligibilityType, FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, formatDiscountAmount, formatInterval, getProductNameByProduct } from '@shared/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolveCountry } from 'src/resolveIntl';

import { t } from '@t';

interface Props {
  price: FinancialPrice;
  title?: string;
}

type ReceiptProps = React.ComponentProps<typeof Receipt>;

export const MembershipSummary: React.FC<Props> = ({ price, title }) => {
  const { t } = useTranslation();

  const country = resolveCountry();

  return (
    <Stack gap={0}>
      <Text py="xs" fw="bold">
        {title || t('membershipSummary')}
      </Text>
      {country === CountryIso3166.Us ? (
        <ReceiptUS price={price} />
      ) : (
        <Receipt items={getReceiptItems(price)} total={getReceiptTotal(price)} />
      )}
    </Stack>
  );
};

const getReceiptTotal = (price: FinancialPrice) => {
  if (!price) return '';

  const hasCoupon = price.campaignAmount != null;

  return formatAmount(hasCoupon ? price.campaignAmount : price.amount, price.currency);
};

const getReceiptItems = (price: FinancialPrice) => {
  if (!price) return [];

  const hasCoupon = price.campaignAmount != null;

  const items: ReceiptProps['items'] = [
    {
      label: `${getProductNameByProduct(price.product)} ${t('membership')}`,
      value: (
        <Text td={hasCoupon ? 'line-through' : null} fw={600}>
          {formatAmount(price.amount, price.currency)}
        </Text>
      ),
    },
    {
      label: (
        <Group gap="xs" justify="center">
          <Text data-secondary>{t('interval')}</Text>
          <Tooltip label={t('membershipInterval.tooltip')}>
            <Icon name="icon-fi-rr-info" size={12} />
          </Tooltip>
        </Group>
      ),
      value: formatInterval(price),
    },
    {
      label: t('fullMobileAppAccess'),
      value: (
        <Text size="md" fw={600}>
          {t('free')}
        </Text>
      ),
    },
  ];

  if (price.product.eligibilityType === EligibilityType.CoachingAndMedication) {
    items.push({
      label: t('shipping'),
      value: (
        <Text size="md" fw={600}>
          {t('free')}
        </Text>
      ),
    });
  }

  if (hasCoupon) {
    items.push(
      ...[
        {
          label: price.campaignText ? t('discountWithText', { text: price.campaignText }) : t('discount'),
          value: (
            <Text fw="bold">
              {price.campaignDiscount?.percentage
                ? ` - ${price.campaignDiscount?.percentage}%`
                : price.campaignDiscount?.amount
                  ? formatDiscountAmount(price.campaignDiscount?.amount, price.campaignDiscount?.currency)
                  : undefined}
            </Text>
          ),
        },
      ],
    );
  }

  return items;
};
