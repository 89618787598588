import { Group, Paper, Stack, Text } from '@mantine/core';
import { EmblaIconName, Icon } from '@shared/components/Icon';
import { FunctionComponent } from 'react';

type InfoRow = { icon?: EmblaIconName; label: string; value: string };
interface IProps {
  config: Array<InfoRow>;
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}
export const KeyValueInfoCard: FunctionComponent<IProps> = ({ config, title, header, footer }) => {
  return (
    <Paper radius={'lg'} shadow="md" w={'100%'} p={'md'}>
      <Stack>
        {header}
        {title ? (
          <Text size="lg" fw={600} ta="center">
            {title}
          </Text>
        ) : null}
        <KeyValueInfoRender config={config} />

        {footer}
      </Stack>
    </Paper>
  );
};

export const KeyValueInfoRender: FunctionComponent<IProps> = ({ config }) => {
  const renderRow = (row: InfoRow) => {
    return (
      <Group key={row.value}>
        <Group justify="space-between" align="flex-start" wrap="nowrap" style={{ flex: 1 }}>
          <Group gap={'sm'} wrap="nowrap">
            <Icon size={18} name={row.icon} data-tertiary />
            <Text data-tertiary size="md">
              {row.label}
            </Text>
          </Group>
          <Text ta="right" data-secondary size="md">
            {row.value}
          </Text>
        </Group>
      </Group>
    );
  };
  return (
    <Stack gap="sm">
      {config?.map((c) => {
        return renderRow(c);
      })}
    </Stack>
  );
};
