import { Anchor, Group, Stack, Text } from '@mantine/core';
import { useFeatureIsOn } from '@shared/FeatureToggleContext';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

export const FeedbackLink: FunctionComponent<{ source: IFunnelContext['source']; embedded: boolean }> = ({
  source,
  embedded,
}) => {
  const on = useFeatureIsOn('signup-feedback');
  const { t } = useTranslation();
  if (!on) {
    return null;
  }

  return (
    <Group flex={1} justify="center">
      <Anchor href={`/feedback?source=${embedded ? 'APP' : source}`} underline="always" ta={'center'}>
        <Stack gap={0}>
          <Text size={'sm'} data-secondary>
            {t('howWasYourExperience')}
          </Text>
          <Text size={'sm'} data-secondary>
            {t('giveUsFeedback')}
          </Text>
        </Stack>
      </Anchor>
    </Group>
  );
};
