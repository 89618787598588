import { Radio, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { TimeOfDay } from '@shared/gql/sdk';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

dayjs.extend(localizedFormat);

export enum PickTimeslotErrors {
  AppointmentNotAvailable = 'appointment-not-available',
}

export const PickTimeOfDay: FunnelPageComponent<
  {
    selectedTimeOfDay?: TimeOfDay;
  },
  IFunnelContext,
  PickTimeslotErrors
> = ({ context: { timeOfDay }, funnelApi }) => {
  const { t } = useTranslation();
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<TimeOfDay>(timeOfDay);

  const translations: Partial<Record<TimeOfDay, string>> = {
    MORNING: t('morning'),
    AFTERNOON: t('afternoon'),
    EVENING: t('evening'),
  };

  const renderCard = (value: TimeOfDay) => {
    return (
      <RadioCard
        checked={selectedTimeOfDay === value}
        value={value}
        label={translations[value]}
        select={() => {
          setSelectedTimeOfDay(value);
        }}
      />
    );
  };

  return (
    <FullScreenContainer>
      <StepTitle title={t('whenIsTheMostConvenientTimeForUsToCall')} description={t('selectThePreferredTimeOfDay')} />
      <Radio.Group value={selectedTimeOfDay}>
        <Stack gap="sm">
          {renderCard(TimeOfDay.Morning)}
          {renderCard(TimeOfDay.Afternoon)}
          {renderCard(TimeOfDay.Evening)}
        </Stack>
      </Radio.Group>
      <Stack gap="xl">
        <Text fw={400} ta="center" data-secondary data-secondary-font>
          {t('wellGetBackToYouPrompty')}
        </Text>
      </Stack>
      <BottomScreenContainer>
        <NextButton disabled={!selectedTimeOfDay} onClick={() => funnelApi.next({ selectedTimeOfDay })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
