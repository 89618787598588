import glyphMap from '@assets/icons/unicodesMap.json';
import { CountryIso3166, EligibilityType, FinancialPrice, FinancialProduct, UtmHeaders } from '@shared/gql/sdk';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';

import { EmblaIconName } from './components/Icon';
import { resolveIntl } from '../src/resolveIntl';

import { t } from '@t';
export const calculateBmi = (weight: number, height: number) => {
  const bmi = weight && height ? weight / ((height * height) / 10000) : undefined;
  return bmi ? Math.trunc(bmi * 10) / 10 : undefined;
};

export const getFirstMatchingValue = <T>(search: string, searchTable: Record<string, T>): T => {
  return searchTable[Object.keys(searchTable).find((k) => search.startsWith(k))];
};

export const getStonesAndPounds = (weight: number) => {
  const stones = Math.floor(weight / 6.35029318);
  const pounds = Math.round((weight % 6.35029318) / 0.45359237);
  return { stones, pounds };
};

export const getRootPath = () => {
  const { country } = resolveIntl();
  const countryCode = country && country !== CountryIso3166.Gb ? country.toLowerCase() : 'uk';
  return `/${countryCode.toLowerCase()}/signup`;
};

export const getUrlParams = () => {
  return new URLSearchParams(window.location.search);
};

export const debugEnabled = () => {
  const enabledInStorage = localStorage.getItem('debug') === 'true';
  const enabledInUrl = getUrlParams().get('debug') === 'true';

  if (!enabledInStorage && enabledInUrl) {
    localStorage.setItem('debug', 'true');
  }

  return enabledInUrl || enabledInStorage;
};

const mapUtmHeaders = (rawUtmHeaders: Record<string, string>): UtmHeaders => {
  const utmHeaders: UtmHeaders = {
    utm_source: rawUtmHeaders.utm_source,
    utm_medium: rawUtmHeaders.utm_medium,
    utm_campaign: rawUtmHeaders.utm_campaign,
    utm_keyword: rawUtmHeaders.utm_keyword,
    utm_content: rawUtmHeaders.utm_content,
    utm_adgroupid: rawUtmHeaders.utm_adgroupid,
    fbclid: rawUtmHeaders.fbclid,
    gclid: rawUtmHeaders.gclid,
  };
  return utmHeaders;
};

export const getUtmHeaders = (): UtmHeaders => {
  const fromStorage = sessionStorage.getItem('utmHeaders');
  if (fromStorage) {
    try {
      return mapUtmHeaders(JSON.parse(fromStorage));
    } catch (e) {
      // ignore
    }
  }

  const urlParams = getUrlParams();
  const utmHeaders: Record<string, string> = {};
  urlParams.forEach((val, key) => {
    if (key.startsWith('utm_') || key === 'fbclid' || key === 'gclid') {
      utmHeaders[key] = val;
    }
  });

  if (Object.keys(utmHeaders)?.length > 0) {
    sessionStorage.setItem('utmHeaders', JSON.stringify(utmHeaders));
    return mapUtmHeaders(utmHeaders);
  }
};

export const validatePhone = (phone: string) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phone);

    return parsedPhoneNumber.isValid() ? null : t('invalidPhoneNumber');
  } catch (err) {
    return t('invalidPhoneNumber');
  }
};

export const validateEmail = (email: string) => {
  // this regex was copied from our email validation in the backend
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) ? null : t('invalidEmail');
};
export { resolveIntl };

const formatDkk = (amount: number) => {
  const decimalAmount = (amount / 100).toFixed(2);
  const [integerPart, decimalPart] = decimalAmount.split('.');

  const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 2,
  });

  const formattedAmount = formatter
    .format(amount / 100)
    .replace('DKK', '')
    .trim();
  return decimalPart === '00' ? `${integerPart},-` : formattedAmount;
};

export const formatAmount = (amount: number, currency: FinancialPrice['currency']) => {
  const decimalAmount = (amount / 100).toFixed(2);

  switch (currency) {
    case 'usd':
      return `$${decimalAmount}`;
    case 'gbp':
      return `£${decimalAmount}`;
    case 'dkk': {
      return formatDkk(amount);
    }
  }
};

export const formatDiscountAmount = (amount: number, currency: FinancialPrice['currency']) => {
  switch (currency) {
    case 'usd':
      return `$${Math.round(amount / 100)}`;
    case 'gbp':
      return ` - £${amount}`;
    case 'dkk': {
      return `- ${formatDkk(amount)}`;
    }
  }
};

export const formatInterval = (price: FinancialPrice, format: 'long' | 'short' | 'per' = 'long') => {
  if (!price) return '';

  switch (price.paymentIntervalCode) {
    case 'month':
      return format === 'long' ? t('monthly') : format === 'per' ? t('monthly.per') : t('monthly.short');
  }
};

/**
 * Retrieves the name for a given product, with custom mappers.
 *
 * @param product - The Financial product.
 * @returns The label for the product type.
 */
export const getProductNameByProduct = (product: Pick<FinancialProduct, 'name' | 'eligibilityType'>) => {
  if (product.name) {
    return product.name;
  }

  switch (product.eligibilityType) {
    case EligibilityType.Coaching:
      return t('productName.emblaCoach');
    case EligibilityType.CoachingAndMedication:
      return t('coachingAndMedication');
  }
};

export const isEmblaIconName = (name: string): name is EmblaIconName => {
  return name in glyphMap;
};
