import { env } from '@env';
import { Box, Card, Image, List, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { Icon } from '@shared/components/Icon';
import { KeyValueInfoRender } from '@shared/components/KeyValueInfoCard';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { PublicBookableScreeningAppointment, Role } from '@shared/gql/sdk';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

dayjs.extend(localizedFormat);
const getRoleLabel = (role: Role) => {
  switch (role) {
    case Role.Doctor:
      return t('doctor');
    case Role.Nurse:
      return t('nurse');
    case Role.Coach:
      return t('coach');
  }
};

export const ConfirmBooking: FunnelPageComponent<
  { selectedTimeslot?: PublicBookableScreeningAppointment },
  IFunnelContext
> = ({ funnelApi, context: { selectedTimeslot } }) => {
  const { t } = useTranslation();
  if (!selectedTimeslot) {
    return null;
  }

  const roleLabel = getRoleLabel(selectedTimeslot.userRole);
  const date = dayjs(selectedTimeslot?.startTime).format('LL');
  const startTime = dayjs(selectedTimeslot?.startTime).format('HH:mm');
  const endTime = dayjs(selectedTimeslot?.endTime).format('HH:mm');
  const imageSrc = selectedTimeslot.profilePictureImageId
    ? `${env.employeeImageAssets}/${selectedTimeslot.profilePictureImageId}`
    : undefined;

  return (
    <FullScreenContainer>
      <StepTitle title={t('bookYourCall')} style={{ maxWidth: 320 }} />
      <Card no-border w={'100%'} maw={440} style={{ alignSelf: 'center' }}>
        <Stack gap={'lg'}>
          <Stack align="center" justify="center" gap={'sm'}>
            <Box w={80} h={80}>
              {imageSrc ? <Image src={imageSrc} w={80} h={80} radius={40} /> : null}
            </Box>
            <Stack gap={0}>
              <Text fw={400} size="lg" ta="center">
                {selectedTimeslot.name}
              </Text>
              <Text fw={400} data-secondary ta="center">
                {roleLabel}
              </Text>
            </Stack>
          </Stack>

          <KeyValueInfoRender
            config={[
              {
                icon: 'icon-fi-rr-calendar-2',
                label: t('date'),
                value: date,
              },
              {
                icon: 'icon-fi-rr-clock',
                label: t('time'),
                value: `${startTime} - ${endTime}`,
              },
              {
                icon: 'icon-fi-rr-mobile-notch',
                label: t('how'),
                value: t('videoConsultation'),
              },
            ]}
          />
          <Card radius={'md'} data-background-dark>
            <Text data-secondary-font data-secondary size={'lg'}>
              {t('whatWeReview')}
            </Text>
            <List>
              <List.Item>
                <Text data-secondary-font data-secondary>
                  {t('challengesAndGoals')}
                </Text>
              </List.Item>
              <List.Item>
                <Text data-secondary-font data-secondary>
                  {t('introductionToEmbla')}
                </Text>
              </List.Item>
              <List.Item>
                <Text data-secondary-font data-secondary>
                  {t('getYourQuestionsAnswered')}
                </Text>
              </List.Item>
            </List>
          </Card>
        </Stack>
      </Card>
      <BottomScreenContainer>
        <GrowingButton
          data-testid="next"
          leftSection={
            <Box mr={'sm'}>
              <Icon name={'icon-fi-rr-check-circle'} size={20} />
            </Box>
          }
          onClick={() => funnelApi.next({})}
          buttonLabel={t('confirmAppointment')}
        />

        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
