import { ProductCard } from '@components/ProductCard';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Box, Group, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getDefaultPriceInfo, getPriceInfoByProductSelection } from '@util/helpers';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';
import { resolveIntl } from 'src/resolveIntl';

import { t } from '@t';

export const ProgramOffer: FunnelPageComponent<
  {
    selectedProduct?: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({ funnelApi, context: { selectedProduct, priceInfos } }) => {
  const { t } = useTranslation();
  const { country } = resolveIntl();
  const coachingAndMedication =
    selectedProduct === ProductSelection.CoachingAndMaybeMedication ||
    selectedProduct === ProductSelection.MedicationAndCoaching;
  const { isMobile } = useIsMobileViewport();
  const priceInfo =
    getPriceInfoByProductSelection(selectedProduct, priceInfos || []) ?? getDefaultPriceInfo(selectedProduct, country);

  const title = coachingAndMedication ? t('medicalWeightLoss') : t('coachingOnly');
  const description = coachingAndMedication ? t('medicalWeightLoss.description') : t('coachingOnly.description');
  return (
    <FullScreenContainer>
      <StepTitle title={title} description={description} />

      <Group justify={'center'}>
        <Box w={isMobile ? '100%' : 420}>
          <ProductCard productSelection={selectedProduct} price={priceInfo} variant={'price-top'} />
        </Box>
      </Group>
      <ProductQuote selectedProduct={selectedProduct} />
      <TrustBoxMicroTrustScore />

      <BottomScreenContainer>
        <NextButton
          onClick={() =>
            funnelApi.next({
              selectedProduct,
            })
          }
        />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const quotes: Partial<Record<ProductSelection, () => { quote: string; author: string }>> = {
  [ProductSelection.CoachingOnly]: () => {
    return { quote: t('besOnlineCoaching'), author: t('quoteSimone') };
  },
  [ProductSelection.MedicationAndCoaching]: () => {
    return { quote: t('bestWay'), author: t('quotePernille') };
  },
  [ProductSelection.CoachingAndMaybeMedication]: () => {
    return { quote: t('bestWay'), author: t('quotePernille') };
  },
};

const ProductQuote: FunctionComponent<{
  selectedProduct: ProductSelection;
}> = ({ selectedProduct }) => {
  if (selectedProduct == null) {
    return null;
  }
  const q = quotes[selectedProduct]?.();

  return (
    <Stack gap={'xs'}>
      <Text data-secondary data-secondary-font size={'xl'} ta={'center'}>
        {`"${q.quote}"`}
      </Text>
      <Text data-secondary size={'lg'} fw={600} ta={'center'}>
        {q.author}
      </Text>
    </Stack>
  );
};
