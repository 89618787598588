import { Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { EmblaNumberInput } from '@shared/components/EmblaNumberInput';
import { EmblaSegmentedControl } from '@shared/components/EmblaSegmentedControl';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { LeftLabel } from '@shared/components/LeftLabel';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { CountryIso3166, UnitSystem } from '@shared/gql/sdk';
import { resolveIntl } from '@shared/helpers';
import { cmToFeetInches, feetInchesToCm, kgToStonesPounds, stonesPoundsToKg } from '@shared/unitConversion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

const numberRequired = (value: number, message?: string) => {
  if (value == null) {
    return message || t('required');
  }
};
const numberInRange = (value: number, range: [number?, number?], msg?: string) => {
  if ((range[0] != null && value < range[0]) || (range[1] != null && value > range[1])) {
    if (msg) return msg;
    let message = t('notInRange');
    if (range[0] != null && range[1] != null) {
      message = t('valueMustBeBetween', { min: range[0], max: range[1] });
    } else if (range[0] == null) {
      message = t('valueMustBeLessThan', { max: range[1] });
    } else if (range[1] == null) {
      message = t('valueMustBeGreaterThan', { min: range[0] });
    }
    return message;
  }
};

const calculateFormFromUserData = (data: { height?: number; weight?: number }) => {
  return {
    height: data.height || undefined,
    weight: data.weight || undefined,
    feet: cmToFeetInches(data.height).feet,
    inches: cmToFeetInches(data.height).inches,
    stones: kgToStonesPounds(data.weight).stones,
    pounds: kgToStonesPounds(data.weight).pounds,
  };
};

const metricOnlyCountries: CountryIso3166[] = [CountryIso3166.Dk];

export const HeightWeight: FunnelPageComponent<
  {
    height: number;
    weight: number;
    unitSystem: UnitSystem;
  },
  IFunnelContext
> = ({ data: { height, weight, unitSystem: _unitSystem }, context: { userSession }, funnelApi }) => {
  const { country } = resolveIntl();
  const countryUnitSystem = country === CountryIso3166.Dk ? UnitSystem.Metric : UnitSystem.Imperial;
  const [unitSystem, setUnitSystem] = useState<UnitSystem>(_unitSystem ? _unitSystem : countryUnitSystem);

  const { t } = useTranslation();

  const form = useForm({
    initialValues: calculateFormFromUserData({ height, weight }),

    validate: {
      height: (value) => {
        if (unitSystem === 'imperial') return;
        const required = numberRequired(value, t('heightCmRequired'));
        const range: [number?, number?] = [100, 250];
        const inRange = numberInRange(value, range, t('heightMustBeBetween', { min: range[0], max: range[1] }));
        return required || inRange;
      },
      weight: (value) => {
        if (unitSystem === 'imperial') return;
        const required = numberRequired(value, t('weightKgRequired'));
        const range: [number?, number?] = [50, 250];
        const inRange = numberInRange(value, range, t('weightMustBeBetween', { min: range[0], max: range[1] }));
        return required || inRange;
      },
      feet: (value) => {
        if (unitSystem === 'metric') return;
        const required = numberRequired(value, t('heightFtRequired'));
        const inRange = numberInRange(value, [4, 7]);
        return required || inRange;
      },
      inches: (value) => {
        if (unitSystem === 'metric') return;
        const required = numberRequired(value, t('heightInRequired'));
        const inRange = numberInRange(value, [0, 11]);
        return required || inRange;
      },
      stones: (value) => {
        if (unitSystem === 'metric') return;
        const required = numberRequired(value, t('weightStRequired'));
        const inRange = numberInRange(value, [6, null]);
        return required || inRange;
      },
      pounds: (value) => {
        if (unitSystem === 'metric') return;
        const required = numberRequired(value, t('weightLbsRequired'));
        const inRange = numberInRange(value, [0, 13]);
        return required || inRange;
      },
    },
  });

  useEffect(() => {
    form.setValues(calculateFormFromUserData({ height, weight }));
  }, [height, weight]);

  const onChange = (field: string) => (value: string | number) => {
    let { height, weight, feet, inches, stones, pounds } = form.values;
    switch (unitSystem) {
      case 'imperial': {
        switch (field) {
          case 'feet':
            feet = parseFloat(String(value));
            break;
          case 'inches':
            inches = parseFloat(String(value));
            break;
          case 'stones':
            stones = parseFloat(String(value));
            break;
          case 'pounds':
            pounds = parseFloat(String(value));
            break;
        }
        const height = feetInchesToCm(feet, inches);
        const weight = stonesPoundsToKg(stones, pounds);

        form.setValues({
          height,
          weight,
          feet,
          inches,
          stones,
          pounds,
        });
        break;
      }
      case 'metric':
      default: {
        {
          switch (field) {
            case 'height':
              height = parseFloat(String(value));
              break;
            case 'weight':
              weight = parseFloat(String(value));
              break;
          }

          const { feet, inches } = cmToFeetInches(height);
          const { stones, pounds } = kgToStonesPounds(weight);
          form.setValues({ height, weight, feet, inches, stones, pounds });
        }
      }
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(() => {
        funnelApi.next({
          height: form.values.height,
          weight: form.values.weight,
          unitSystem,
        });
      })}
      style={{ flex: 1 }}
    >
      <FullScreenContainer>
        <StepTitle
          title={t('whatsYourHeightAndWeight', { name: userSession?.firstName?.toUpperCase() })}
          description={t('toKnowIfYoureAMatchWeNeedYourWeight')}
        />

        <Stack gap={'md'}>
          {!metricOnlyCountries.includes(country) && (
            <LeftLabel label={t('iAmUsing')}>
              <EmblaSegmentedControl
                data={[
                  { label: t('imperial'), value: 'imperial' },
                  { label: t('metric'), value: 'metric' },
                ]}
                onChange={(value) => {
                  setUnitSystem(value as UnitSystem);
                }}
                value={unitSystem}
              />
            </LeftLabel>
          )}
          {unitSystem === 'metric' ? (
            <>
              <LeftLabel label={t('myHeight')}>
                <EmblaNumberInput
                  {...form.getInputProps('height')}
                  mode="measurement"
                  width="12rem"
                  suffix="cm"
                  decimalScale={0}
                  onChange={onChange('height')}
                />
              </LeftLabel>
              <LeftLabel label={t('myWeight')}>
                <EmblaNumberInput
                  {...form.getInputProps('weight')}
                  mode="measurement"
                  width="12rem"
                  suffix="kg"
                  onChange={onChange('weight')}
                  step={0.1}
                  decimalScale={1}
                  decimalSeparator="."
                />
              </LeftLabel>
            </>
          ) : (
            <>
              <LeftLabel label={t('myHeight')}>
                <Group align="center" gap="sm">
                  <EmblaNumberInput
                    {...form.getInputProps('feet')}
                    mode="measurement"
                    width="6rem"
                    suffix="ft"
                    onChange={onChange('feet')}
                  />
                  <EmblaNumberInput
                    {...form.getInputProps('inches')}
                    mode="measurement"
                    width="6rem"
                    suffix="in"
                    onChange={onChange('inches')}
                  />
                </Group>
              </LeftLabel>

              <LeftLabel label={t('myWeight')}>
                <Group align="center" gap="sm">
                  <EmblaNumberInput
                    {...form.getInputProps('stones')}
                    mode="measurement"
                    width="6rem"
                    suffix="st"
                    onChange={onChange('stones')}
                  />
                  <EmblaNumberInput
                    {...form.getInputProps('pounds')}
                    mode="measurement"
                    width="6rem"
                    suffix="lbs"
                    onChange={onChange('pounds')}
                  />
                </Group>
              </LeftLabel>
            </>
          )}
        </Stack>
        <BottomScreenContainer>
          <NextButton type="submit" />
          <BackButton onClick={() => funnelApi.back()} />
        </BottomScreenContainer>
      </FullScreenContainer>
    </form>
  );
};
