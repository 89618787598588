import { showNotification } from '@mantine/notifications';

import { t } from '@t';

export const showErrorNotification = (message: string, autoClose: number | boolean = false) => {
  showNotification({
    message,
    title: t('error'),
    datatype: 'error',
    autoClose,
  });
};
