import { env } from '@env';
import api from '@shared/api';
import { resolveIntl } from '@shared/helpers';

export const startPasswordlessLogin = async (phoneNumber: string) => {
  const {
    auth0: { domain, clientID },
  } = env;

  await fetch(`https://${domain}/passwordless/start`, {
    method: 'POST',
    body: JSON.stringify({
      client_id: clientID,
      connection: 'sms',
      phone_number: phoneNumber,
      send: 'code',
      authParams: {
        scope: 'openid email profile',
      },
    }),
    headers: { 'Content-Type': 'application/json' },
  });
};

export const completePasswordlessLogin = async (phoneNumber: string, code: string) => {
  const {
    auth0: { domain, clientID },
  } = env;

  const result = await fetch(`https://${domain}/oauth/token`, {
    method: 'POST',
    body: JSON.stringify({
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      client_id: clientID,
      realm: 'sms',
      username: phoneNumber,
      otp: code,
      scope: 'openid email profile',
    }),
    headers: { 'Content-Type': 'application/json' },
  });

  const resultJson = await result.json();

  if (resultJson?.access_token) {
    return resultJson.access_token;
  }

  return;
};

export const performPasswordLessLogin = async (phoneNumber: string, code: string) => {
  const { locale } = resolveIntl();
  try {
    const auth0Token = await completePasswordlessLogin(phoneNumber, code);
    const loginResult = await api.UserLoginAuth0V2({
      auth0Token,
      locale,
    });

    if (loginResult.UserLoginAuth0V2.token) {
      return {
        ...loginResult.UserLoginAuth0V2,
      };
    }
  } catch (err) {
    return;
  }
};
