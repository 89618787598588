import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { hydrateDataFromSession } from '@util/hydration';
import { FunctionComponent, useRef } from 'react';

import { createPartnerFunnelConfig } from './configs/us-partner-signup/config';
import { IFunnelSession } from './FunnelContext';

import { t } from '@t';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'intro':
      return t('embla');
    case 'registration':
      return t('registration');
    case 'checkout':
      return t('checkout');
  }
};

const getConfig = (session: IFunnelSession) => {
  const config = createPartnerFunnelConfig({ ...session.context });
  config.initialData = hydrateDataFromSession(config.config, session);
  return config;
};

export const FunnelUS: FunctionComponent<{ session: IFunnelSession }> = ({ session }) => {
  const funnelConfig = useRef(getConfig(session)).current;

  return (
    <Funnel
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      debug={debugEnabled()}
      name="v3-us"
      options={funnelConfig}
    />
  );
};
