const getRegion = (): Region => {
  if (window.location.pathname.startsWith('/us/')) {
    return 'US';
  }

  return 'EU';
};

const getConfigValue = <T extends keyof (RegionConfig & CommonConfig)>(key: T): ImportMetaEnv[T] => {
  const region = getRegion();
  const fallbackValue = import.meta.env[key];
  if (!region) return fallbackValue;

  return import.meta.env[`${key}_${region}`] || fallbackValue;
};

export const env = (function () {
  return {
    serverRoot: getConfigValue('VITE_SERVER_ROOT_URL'),
    disableLoading: getConfigValue('VITE_DISABLE_LOAD') === 'true',
    gqlUrl: `${getConfigValue('VITE_SERVER_ROOT_URL')}/graphql`,
    gtmId: getConfigValue('VITE_GTM_ID'),
    imageAssets: `${getConfigValue('VITE_SERVER_ROOT_URL')}/assets/profile-images`,
    employeeImageAssets: `${getConfigValue('VITE_SERVER_ROOT_URL')}/employee-assets/images`,
    partnerCoupon: getConfigValue('VITE_PARTNER_COUPON'),
    recaptchaKey: getConfigValue('VITE_RECAPTCHA_KEY'),
    growthbook: {
      clientKey: getConfigValue('VITE_GROWTHBOOK_CLIENT_KEY'),
      apiHost: getConfigValue('VITE_GROWTHBOOK_API_HOST'),
    },
    auth0: {
      clientID: getConfigValue('VITE_AUTH0_CLIENT_ID'),
      domain: getConfigValue('VITE_AUTH0_DOMAIN'),
    },
  };
})();
