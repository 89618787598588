import projectionGraphCoachingOnly from '@assets/weight-loss-prediction-coaching-only.svg';
import { Group, Image, Stack } from '@mantine/core';
import { IndicatorWithLabel } from '@shared/components/IndicatorWithLabel';
import { StepTitle } from '@shared/components/StepTitle';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const Coaching: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="lg" w={'100%'}>
      <StepTitle title={t('loseWeightWithoutStarvingYourself')} description={t('yourWeightLossWithEmbla')} />
      <Stack maw={430} w="100%" justify="space-between" flex={1}>
        <WeightProjectionGraphCoachingOnly />
        <Group justify="center" style={{ width: '100%' }}>
          <IndicatorWithLabel label={t('restrictiveDiet')} indicatorColor={'#808080'} />
          <IndicatorWithLabel label={t('emblaCoaching')} indicatorColor={'#000000'} />
        </Group>
      </Stack>
    </Stack>
  );
};

const WeightProjectionGraphCoachingOnly: FunctionComponent = () => {
  return (
    <Group w="100%" align="stretch" gap={'sm'} mih={400} justify="center">
      <div
        style={{
          display: 'flex',
          paddingTop: 10,
          paddingBottom: 10,
          position: 'relative',
          flex: 1,
        }}
      >
        <Image
          style={{
            flex: 1,
            overflow: 'visible',
          }}
          src={projectionGraphCoachingOnly}
        />
      </div>
    </Group>
  );
};
