import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { StateSelect } from '@util/StateSelect';
import { useState } from 'react';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const StateOfResidency: FunnelPageComponent<{ state: string }, IFunnelContext> = ({ data, funnelApi }) => {
  const [selectedState, setSelectedState] = useState<string>(data?.state || 'TX');

  return (
    <FullScreenContainer>
      <StepTitle title={t('whereAreYouLocated')} description={t('pleaseStateYourPrimaryStateOfResidence')} />
      <StateSelect value={selectedState} onChange={setSelectedState} />

      <BottomScreenContainer>
        <NextButton disabled={!selectedState} onClick={() => funnelApi.next({ state: selectedState })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
