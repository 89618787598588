import { useUserSession } from '@hooks/sessionHooks';
import { FunctionComponent } from 'react';
import { IFunnelSession } from 'src/FunnelContext';

export const App: FunctionComponent<{
  funnelComponent: FunctionComponent<{
    session: IFunnelSession;
  }>;
}> = (props) => {
  const { initialized, session } = useUserSession();
  if (!initialized) return null;
  return <props.funnelComponent session={session} />;
};
