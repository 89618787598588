import { Registration, RegistrationErrors } from '@pages/uk/Registration';
import api from '@shared/api';
import { FunnelPageHandler } from '@shared/funnel-engine';
import { UserSignInFlowDecision } from '@shared/gql/sdk';
import { getUtmHeaders, resolveIntl } from '@shared/helpers';

export const registrationExitHandler: FunnelPageHandler<typeof Registration> = async ({ context, funnelApi, data }) => {
  const { country, locale } = resolveIntl();
  if (!context.userSession) {
    const decisionResult = await api.UserGetSignInFlow({
      phoneNumber: data.phone,
    });

    switch (decisionResult.UserGetSignInFlow.decision) {
      case UserSignInFlowDecision.Continue:
        funnelApi.updateContext({
          flowVariant: 'phone-already-registered',
          userSession: {
            firstName: data.firstName,
            phoneNumber: data.phone,
            token: null,
            userId: null,
            phoneConfirmed: false,
          },
        });
        return;
      case UserSignInFlowDecision.Email:
      case UserSignInFlowDecision.Signup:
        {
          try {
            const utmHeaders = getUtmHeaders();
            const result = await api.UserCreate({
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phone,
              localeISO6391: locale,
              countryISO3166: country,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              utmHeaders,
              recaptchaToken: data.recaptchaToken,
              metaData: JSON.stringify({ subscribed_via_ecom: true }),
            });
            funnelApi.updateContext({
              userSession: {
                firstName: data.firstName,
                token: result.UserCreate.token,
                refreshToken: result.UserCreate.refreshToken,
                userId: result.UserCreate.userId,
                phoneNumber: data.phone,
                phoneConfirmed: false,
                email: data.email,
              },
            });

            sessionStorage.setItem('token', result.UserCreate.token);
          } catch (err: unknown) {
            if (err?.toString().includes('E-mail or phone number is not accepted')) {
              return RegistrationErrors.PhoneOrEmailAlreadyInUse;
            } else {
              throw err;
            }
          }
        }
        break;
      default:
        return RegistrationErrors.PhoneOrEmailAlreadyInUse;
    }
  }
  api.SignupFunnelMarkAsLead();
};
