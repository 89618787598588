import { ProductCardSelect } from '@components/ProductCardSelect';
import { Grid, Loader, Stack, Text, UnstyledButton } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

export const ChooseProgram: FunnelPageComponent<
  {
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({ context: { selectedProduct, eligibleFor, selectedProductPriceInfo: price, priceInfos }, funnelApi }) => {
  const [selected, setSelected] = useState(selectedProduct || eligibleFor);
  const { t } = useTranslation();

  if (!price) return <Loader />;

  const emblaThrivePrice = priceInfos.find((p) => p.name === 'EmblaThrive');
  const emblaThriveMDPrice = priceInfos.find((p) => p.name === 'EmblaThriveMD');
  return (
    <FullScreenContainer>
      <StepTitle title={t('chooseYouProgram')} />
      <Stack gap="sm">
        <Text py="xs" fw="bold">
          {t('chooseYouProgram')}
        </Text>
        <Grid grow>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <UnstyledButton
              w={'100%'}
              onClick={() => {
                setSelected(ProductSelection.MedicationAndCoaching);
              }}
            >
              <ProductCardSelect
                product={ProductSelection.MedicationAndCoaching}
                price={emblaThriveMDPrice}
                selected={selected === ProductSelection.MedicationAndCoaching}
              />
            </UnstyledButton>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <UnstyledButton
              w={'100%'}
              onClick={() => {
                setSelected(ProductSelection.CoachingOnly);
              }}
            >
              <ProductCardSelect
                product={ProductSelection.CoachingOnly}
                price={emblaThrivePrice}
                selected={selected === ProductSelection.CoachingOnly}
              />
            </UnstyledButton>
          </Grid.Col>
        </Grid>
      </Stack>
      <TrustBoxMicroTrustScore />

      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({ selectedProduct: selected })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
