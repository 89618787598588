import { Icon } from '@shared/components/Icon';
import { MouseEventHandler } from 'react';

import { GrowingButton } from './GrowingButton';

interface IProps {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  grow?: boolean;
}

export const BackButton: React.FunctionComponent<IProps> = ({ grow, onClick, disabled }) => {
  return (
    <GrowingButton
      variant="transparent"
      disabled={disabled}
      forceGrow={grow}
      leftSection={<Icon name={'icon-fi-rr-arrow-left'} size={20} />}
      onClick={onClick}
    />
  );
};
