import { Card, CheckboxProps, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

import { EmblaCheckbox } from './EmblaCheckbox';

export const CheckboxCard: FunctionComponent<
  Omit<CheckboxProps, 'onChange'> & { onChange: (value: boolean) => void }
> = ({ onChange, ...props }) => {
  return (
    <Card
      data-checked={props.checked || undefined}
      onClick={() => {
        onChange(!props.checked);
      }}
    >
      <EmblaCheckbox
        lh={24}
        checked={props.checked}
        readOnly
        label={
          <Text fw={400} size="lg" onClick={(event) => event.stopPropagation()}>
            {props.label}
          </Text>
        }
        description={props.description}
      />
    </Card>
  );
};
