import { Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { DateInputWithMask } from '@shared/components/DateInputWithMask';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';
import { resolveIntl } from 'src/resolveIntl';

dayjs.extend(customParseFormat);

export const BirthDate: FunnelPageComponent<
  {
    value: Date;
  },
  IFunnelContext
> = ({ data: { value }, funnelApi }) => {
  const { t } = useTranslation();
  const { dateFormat } = resolveIntl();
  const form = useForm({
    initialValues: {
      birthDate: {
        date: value ? new Date(value) : null,
        valid: value && dayjs(value, dateFormat, true).isValid(),
        dateString: value ? dayjs(value).format(dateFormat) : null,
      },
    },
    validate: {
      birthDate: (value) => {
        return !value.date ? t('required') : null;
      },
    },
  });

  return (
    <form
      onSubmit={form.onSubmit(() => {
        funnelApi.next({
          value: form.values.birthDate.date,
        });
      })}
      style={{ flex: 1 }}
    >
      <FullScreenContainer>
        <Stack justify="center" align="center" gap={'xxl'}>
          <StepTitle title={t('whatsYourDateOfBirth')} />
          <Text size={'lg'} ta={'center'} mb={'md'} data-secondary-font>
            {t('weDontOfferTreatmentToPeopleUnderTheAgeOf18OrAbove75YearsOld')}
          </Text>
        </Stack>

        <Stack gap={'xl'}>
          <DateInputWithMask {...form.getInputProps('birthDate')} />
        </Stack>
        <BottomScreenContainer>
          <NextButton type="submit" disabled={!form.values.birthDate?.valid} />
          <BackButton onClick={() => funnelApi.back()} />
        </BottomScreenContainer>
      </FullScreenContainer>
    </form>
  );
};
