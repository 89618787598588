import glyphMap from '@assets/icons/unicodesMap.json';
import { FunctionComponent } from 'react';

export type EmblaIconName = keyof typeof glyphMap;
type IconSize = 'sm' | 'md' | 'lg';

interface IProps {
  name: EmblaIconName;
  size?: number | IconSize;
  className?: string;
  color?: string;
}

const fontSizeMap: Record<IconSize, number> = {
  sm: 16,
  md: 18,
  lg: 20,
};

export const Icon: FunctionComponent<IProps> = ({ name, className, size = 'sm', color, ...props }) => {
  return (
    <i
      style={{ fontSize: typeof size === 'number' ? size : fontSizeMap[size], color }}
      className={`icon ${name} ${className || ''}`}
      {...props}
    />
  );
};
