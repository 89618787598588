import { Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const NotEligible: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context, funnelApi }) => {
  const { embedded, notEligibleReason, measurements } = context;
  const { bmi } = measurements;
  const { title, mdText1, mdText2 } = useNotEligibleContent(notEligibleReason, bmi);
  const showDownloadButton = notEligibleReason === 'low-bmi' || notEligibleReason === 'addiction';
  const showDownloadLabel = showDownloadButton || notEligibleReason === 'very-low-bmi';
  return (
    <FullScreenContainer gap="lg" py="md">
      <StepTitle title={title} description={mdText1} description2={mdText2} />

      <BottomScreenContainer>
        {showDownloadLabel && !embedded ? (
          <>
            <Text ta="center" data-primary size="lg" fw={600}>
              {t('youAreWelcomeToDownload')}
            </Text>
            {showDownloadButton ? (
              <>
                <DownloadButton />
                <BackButton onClick={() => funnelApi.back()} />
                <PhoneImage />
              </>
            ) : null}
          </>
        ) : (
          <>
            {showDownloadButton && embedded && (
              <Text ta="center" data-primary size="lg" fw={600}>
                {t('youAreWelcomeToUse')}
              </Text>
            )}
            <BackButton onClick={() => funnelApi.back()} />
          </>
        )}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const useNotEligibleContent = (reason: IFunnelContext['notEligibleReason'], bmi: number) => {
  const title = t('thanksForYourAnswers');
  let mdText1;
  let mdText2;

  switch (reason) {
    case 'low-bmi':
    case 'very-low-bmi':
      mdText1 = t('notEligibleBmi1', { bmi });
      mdText2 = t('notEligibleBmi2');
      break;
    case 'eating-disorder':
      mdText1 = t('notEligibleEatingDisorder');
      mdText2 = t('notEligibleEatingDisorder2');
      break;
    case 'addiction':
      mdText1 = t('notEligibleDrugsAlcohol');
      break;
  }

  return {
    title,
    mdText1,
    mdText2,
  };
};
