import { Loader, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address, CountryIso3166 } from '@shared/gql/sdk';
import { showErrorNotification } from '@shared/showNotification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';
import { resolveIntl } from 'src/resolveIntl';

import SubscriptionFlow from './Subscribe';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const Pay: FunnelPageComponent<{ address: Address; showBackButton?: boolean }, IFunnelContext> = ({
  data: { address, showBackButton = true },
  context: { selectedProduct, selectedProductPriceInfo: price, stripeCredentials },
  funnelApi,
}) => {
  const [confirmErrorMessage, setConfirmErrorMessage] = useState<string>();
  const { t } = useTranslation();

  const { country } = resolveIntl();
  const isUs = country === CountryIso3166.Us;

  if (!price) return <Loader />;

  return (
    <FullScreenContainer>
      <StepTitle title={isUs ? t('choosePaymentMethod') : t('justOneStepLeft')} />
      <Stack gap="lg">
        <YourRecommendedMembership
          price={price}
          selectedProduct={selectedProduct}
          selected
          compact
          label={isUs ? t('yourChosenProgram') : undefined}
        />
      </Stack>
      <BottomScreenContainer>
        <Stack gap={'xs'}>
          <Text data-secondary fw="bold">
            {t('payWith')}
          </Text>
          {confirmErrorMessage && <Text>{confirmErrorMessage}</Text>}
        </Stack>
        <SubscriptionFlow
          {...stripeCredentials}
          onSuccess={() =>
            funnelApi.next({
              address,
            })
          }
          onError={(errorMessage) => {
            setConfirmErrorMessage(errorMessage);
            showErrorNotification(errorMessage);
          }}
          requiresConfirmation={false}
          submitLabel={t('pay')}
        />
        {showBackButton && <BackButton onClick={() => funnelApi.back()} />}
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

export const createPayPage = (showBackButton: boolean) => {
  return (props: React.ComponentProps<typeof Pay>) => {
    const {
      data: { address },
      ...restProps
    } = props; // Destructure address and other props
    return <Pay data={{ address, showBackButton }} {...restProps} />;
  };
};
