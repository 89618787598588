import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Alert, Badge, Box, Button, Group, Paper, Stack, Text } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import { useTheme } from '@shared/theme';
import { FunctionComponent, MouseEventHandler } from 'react';

import classes from './css-modules/Disclaimer.module.css';

interface IProps {
  type: 'info' | 'warning' | 'error';
  pillText: string;
  message: string;
  action?: { label: string; onClick: MouseEventHandler<HTMLButtonElement> };
}

export const Disclaimer: FunctionComponent<IProps> = ({ type, pillText, message, action }) => {
  const { isMobile } = useIsMobileViewport();
  const { theme } = useTheme();

  return (
    <Paper shadow="xs" radius={'lg'} style={{ width: '100%' }}>
      <Alert
        variant="filled"
        styles={{
          body: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          },
        }}
        data-type={type}
        classNames={{ root: classes.root }}
        title={
          <Badge
            p="md"
            fw={600}
            fz={16}
            tt="none"
            radius={100}
            variant="filled"
            data-type={type}
            classNames={{ root: classes.badge }}
          >
            <Group align="center" gap="sm">
              <Box data-type={type} className={classes.badgeIconWrapper}>
                <Icon
                  size={16}
                  name={'icon-fi-rr-info'}
                  data-type={type}
                  data-secondary
                  color={theme.other.theme.funnel.text.secondary}
                />
              </Box>

              <Text data-type={type} classNames={{ root: classes.badgeText }}>
                {pillText}
              </Text>
            </Group>
          </Badge>
        }
      >
        <Stack justify="center">
          <Text ta="center" data-type={type} classNames={{ root: classes.text }}>
            {message}
          </Text>
          {action ? (
            <Group grow={isMobile} justify="center">
              <Button
                mb={'xs'}
                size={'md'}
                miw={222}
                data-type={type}
                classNames={{ root: classes.actionButton }}
                onClick={action.onClick}
                variant="outline"
              >
                {action.label}
              </Button>
            </Group>
          ) : null}
        </Stack>
      </Alert>
    </Paper>
  );
};
