import anneVibe from '@assets/images/employees/anneVibe.png';
import { CoachName } from '@loaders/employeeLoaders';
import { Group, Image, Stack } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

export const Welcome: FunnelPageComponent<{ continue: boolean }, IFunnelContext> = ({ context, funnelApi }) => {
  const { t } = useTranslation();
  const {
    partnerClientSession: {
      clientInfo: { firstName },
    },
    partnerInfo: { name },
  } = context;
  return (
    <FullScreenContainer>
      <Stack gap={'md'}>
        <Group justify="center" mt={40}>
          <Image w={180} h={180} src={anneVibe} />
        </Group>

        <StepTitle
          title={t('welcome', { name: firstName })}
          description={t('welcome.description', { partnerName: name })}
        />

        <CoachName name={'Anne Vibe'} />
      </Stack>

      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({ continue: true })} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
