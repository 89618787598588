import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Group, Progress, Stack, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

export const TopBar: FunctionComponent<{
  sections: { label: string; percentComplete: number }[];
}> = ({ sections }) => {
  const { isMobile } = useIsMobileViewport();
  const percentsPerSection = 100 / sections.length;

  return (
    <Stack w="100%">
      <Group grow gap={isMobile ? 'xs' : 'lg'}>
        {sections.map((item, index) => (
          <TopBarItem
            key={`${item.label}-${index}`}
            activeOrCompleted={percentsPerSection * (index + 1) < item.percentComplete + percentsPerSection}
            {...item}
          />
        ))}
      </Group>
    </Stack>
  );
};

interface ITopBarItem {
  label: string;
  percentComplete: number;
  activeOrCompleted: boolean;
}

const TopBarItem = ({ label, percentComplete, activeOrCompleted }: ITopBarItem) => {
  return (
    <Stack gap={'xs'}>
      <Progress value={percentComplete} size={4} animated />
      <Text ta="center" size={'sm'} c={activeOrCompleted ? 'secondary' : 'tertiary'}>
        {label}
      </Text>
    </Stack>
  );
};
