import { ChartTooltip, LineChart, LineChartSeries } from '@mantine/charts';
import { Tabs } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { MeasurementType } from '@shared/gql/sdk';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

type ChartInfo = {
  label: string;
  unit: string;
  series: LineChartSeries[];
  xDomain?: [number | string, number | string];
  yDomain?: [number | string, number | string];
};
const measurementTypeChartInfo = (measurementType: MeasurementType): ChartInfo => {
  switch (measurementType) {
    case MeasurementType.WeightKg:
      return {
        label: t('weight'),
        unit: t('kg'),
        xDomain: ['dataMin', 'dataMax'],
        yDomain: ['dataMin - 10', 'dataMax + 10'],
        series: [{ name: 'value', label: t('weight'), color: 'var(--embla-theme-chart-line)' }],
      };
    case MeasurementType.BloodPressure:
      return {
        label: t('bloodPressure'),
        unit: 'mmHg',
        xDomain: ['dataMin', 'dataMax'],
        yDomain: [40, 'dataMax + 10'],
        series: [
          { name: 'systolic', label: t('bloodPressure.systolic'), color: 'var(--embla-theme-rainbow-foreground-v1)' },
          { name: 'diastolic', label: t('bloodPressure.diastolic'), color: 'var(--embla-theme-rainbow-foreground-v2)' },
        ],
      };
    case MeasurementType.Cholesterol:
      return {
        label: t('cholesterol'),
        unit: 'mg/Dl',
        xDomain: ['dataMin', 'dataMax'],
        series: [
          { name: 'total', label: t('cholesterol.total') },
          { name: 'hdl', label: t('cholesterol.hdl'), color: 'var(--embla-theme-indicator-success-background)' },
          { name: 'ldl', label: t('cholesterol.ldl'), color: 'var(--embla-theme-indicator-danger-background)' },
        ],
      };
    case MeasurementType.HbA1c:
      return {
        label: t('hba1c'),
        unit: 'mmol/mol',
        xDomain: ['dataMin', 'dataMax'],
        series: [{ name: 'value', label: t('hba1c'), color: 'var(--embla-theme-chart-line)' }],
      };
  }
};

export const MeasurementStats: FunnelPageComponent<undefined, IFunnelContext> = ({ context, funnelApi }) => {
  const { t } = useTranslation();
  const measuements = context.partnerClientSession.clientInfo.measurements;
  const measurementTypes = measuements.reduce<MeasurementType[]>((prev, cur) => {
    if (prev.includes(cur.type as MeasurementType)) return prev;
    return [...prev, cur.type as MeasurementType];
  }, []);

  return (
    <FullScreenContainer>
      <StepTitle title={t('measurementStats.title')} />
      <Tabs defaultValue={measurementTypes?.[0]}>
        <Tabs.List justify="center">
          {measurementTypes.map((type) => (
            <Tabs.Tab key={`tabHeader-${type}`} value={type}>
              {measurementTypeChartInfo(type)?.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {measurementTypes.map((type) => {
          const info = measurementTypeChartInfo(type);

          return (
            <Tabs.Panel py="md" key={`tabContent-${type}`} value={type}>
              <LineChart
                withPointLabels
                h={300}
                dataKey="date"
                withLegend={info?.series?.length > 1}
                unit={info?.unit}
                series={info?.series}
                xAxisLabel={t('date')}
                yAxisProps={{ type: 'number', domain: info?.yDomain, tickCount: 8, minTickGap: 5 }}
                xAxisProps={{
                  scale: 'time',
                  type: 'number',
                  domain: info?.xDomain,
                  tickFormatter: (val) => dayjs.unix(val).format('ll'),
                  tickCount: 4,
                }}
                tooltipProps={{
                  content: (props) => (
                    <ChartTooltip
                      label={dayjs.unix(props.label).format('ll')}
                      series={info?.series}
                      payload={props.payload}
                      unit={info?.unit}
                    />
                  ),
                }}
                data={measuements
                  .filter((m) => m.type === type)
                  .map((m) => ({
                    ...m,
                    date: dayjs(m.timestamp).unix(),
                    value: parseInt(m.value),
                    ...(m.complexValue ?? {}),
                  }))}
              />
            </Tabs.Panel>
          );
        })}
      </Tabs>
      <BottomScreenContainer>
        <NextButton onClick={funnelApi.next} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
