import anneVibe from '@assets/images/employees/anneVibe.png';
import { CoachName } from '@loaders/employeeLoaders';
import { Group, Image, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

export const ProbablyEligible: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ funnelApi }) => {
  const { t } = useTranslation();

  return (
    <FullScreenContainer>
      <Stack gap={'md'}>
        <Group justify="center" mt={40}>
          <Image w={180} h={180} src={anneVibe} />
        </Group>
        <Text ta="center" size="24px" fw={600}>
          {t('maybeYouMatch')}
        </Text>
        <Text ta="center" data-secondary size={'lg'}>
          {t('qInterestedBookAScreening')}
        </Text>
        <CoachName name={'Anne Vibe'} />
      </Stack>

      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({})} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
