import { ReactComponent as CheckIcon } from '@assets/icons/fi-rr-check.svg';
import { Card, Radio, RadioProps, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

export const RadioCard: FunctionComponent<RadioProps & { select: () => void }> = ({ select, ...props }) => {
  return (
    <Card data-checked={props.checked || undefined} onClick={() => select()}>
      <Radio
        icon={CheckIcon}
        lh={24}
        styles={{
          error: {
            color: 'var(--embla-theme-form-validation-error)',
            fontSize: 'var(--mantine-font-size-sm)',
          },
        }}
        label={
          <Text fw={400} size={'lg'} onClick={(event) => event.stopPropagation()}>
            {props.label}
          </Text>
        }
        {...props}
      />
    </Card>
  );
};
