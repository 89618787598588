import avatar from '@assets/avatar_180px.svg';
import { Group, Image, Stack } from '@mantine/core';
import { BulletpointCard } from '@shared/components/BulletpointCard';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { ReactNode } from 'react';
import IFunnelContext from 'src/FunnelContext';
type UtilityPageBaseOptions = {
  content?: ReactNode;
  nextLabel?: string;
  hideNextButton?: boolean;
  showTrustpilot?: boolean;
};
type UtilityPageOptions<T> = UtilityPageBaseOptions & T;

type TitleTextSection = {
  title: ReactNode;
  text?: ReactNode;
  image?: boolean;
};

const createTitleTextSectionsPage =
  (
    options: UtilityPageOptions<{
      sections: TitleTextSection[];
    }>,
  ): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <FullScreenContainer>
        <Stack flex={1} justify="center" gap={'lg'}>
          <Stack my="xl">
            {options.sections.map(({ title, text, image }) => (
              <Stack gap={'md'}>
                {image ? (
                  <Group justify="center" align="center">
                    <Image src={avatar} w={80} h={80} />
                  </Group>
                ) : null}
                <StepTitle title={title} description={text} />
              </Stack>
            ))}
          </Stack>
          {options.content}
          {options.showTrustpilot && <TrustBoxMicroTrustScore />}
        </Stack>
        <BottomScreenContainer>
          <NextButton label={options.nextLabel} onClick={funnelApi.next} />
          {funnelApi.state.hasPreviousPage && <BackButton onClick={funnelApi.back} />}
        </BottomScreenContainer>
      </FullScreenContainer>
    );
  };

const createTitleTextPage =
  (options: UtilityPageOptions<TitleTextSection>): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <FullScreenContainer>
        <Stack flex={1} justify="center" gap={'lg'}>
          <StepTitle title={options.title} description={options.text} />

          {options.content}
          {options.showTrustpilot && <TrustBoxMicroTrustScore />}
        </Stack>
        <BottomScreenContainer>
          {!options.hideNextButton && <NextButton label={options.nextLabel} onClick={funnelApi.next} />}
          {funnelApi.state.hasPreviousPage && <BackButton onClick={funnelApi.back} />}
        </BottomScreenContainer>
      </FullScreenContainer>
    );
  };

const createTitleBulletPointsPage =
  (
    options: UtilityPageOptions<{ title: ReactNode; text?: string; bulletpoints: ReactNode[] }>,
  ): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <FullScreenContainer>
        <Stack flex={1} justify="center" gap={'lg'}>
          <StepTitle title={options.title} description={options.text} />
          <Stack gap="md">
            {options.bulletpoints.map((b) => (
              <BulletpointCard label={b} />
            ))}
          </Stack>

          {options.content}
          {options.showTrustpilot && <TrustBoxMicroTrustScore />}
        </Stack>
        <BottomScreenContainer>
          {!options.hideNextButton && <NextButton label={options.nextLabel} onClick={funnelApi.next} />}
          {funnelApi.state.hasPreviousPage && <BackButton onClick={funnelApi.back} />}
        </BottomScreenContainer>
      </FullScreenContainer>
    );
  };

export const PageTemplates = {
  TitleText: createTitleTextPage,
  TitleBulletPoints: createTitleBulletPointsPage,
  TitleTextSections: createTitleTextSectionsPage,
};
