import { Anchor, Card, Grid, Group, Stack, Text } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import { CountryIso3166 } from '@shared/gql/sdk';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { resolveIntl } from 'src/resolveIntl';

export const FaqCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const { country } = resolveIntl();

  const renderItem = (icon: React.ReactNode, link: React.ReactNode) => {
    return (
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Group justify="flex-start" gap={'xs'}>
          {icon}
          {link}
        </Group>
      </Grid.Col>
    );
  };
  return (
    <Card flex={1}>
      <Stack>
        <Text size="md">{t('haveAnyQuestions')}</Text>
        <Grid>
          {renderItem(
            <Icon name="icon-fi-rr-comment" />,
            <Anchor data-secondary fw={400} href="https://www.joinembla.com/uk/faq" target="_blank">
              {t('chatWithUs')}
            </Anchor>,
          )}
          {country === CountryIso3166.Gb
            ? renderItem(
                <Icon name="icon-fi-rr-phone-call" />,
                <Anchor data-secondary fw={400} href="tel:02033147918">
                  {t('callUs', { tel: '020 3314 7918' })}
                </Anchor>,
              )
            : null}
        </Grid>
      </Stack>
    </Card>
  );
};
