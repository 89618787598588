import { Loader, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { Medication } from './Medication';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const Recommendation: FunnelPageComponent<
  {
    selectedProduct: ProductSelection;
  },
  IFunnelContext
> = ({ context: { selectedProduct, selectedProductPriceInfo: price }, funnelApi }) => {
  const { t } = useTranslation();

  if (!price) return <Loader />;

  return (
    <FullScreenContainer>
      <StepTitle title={t('weBelieveThisPlanFitsYouBest')} />
      <Stack gap="lg">
        <YourRecommendedMembership price={price} selectedProduct={selectedProduct} selected={true} />

        <TrustBoxMicroTrustScore />
        {selectedProduct === ProductSelection.MedicationAndCoaching && <Medication />}
      </Stack>
      <BottomScreenContainer>
        <NextButton onClick={() => funnelApi.next({ selectedProduct })} />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
