import { env } from '@env';
import { GrowthBook } from '@growthbook/growthbook';
import { resolveIntl } from '@shared/helpers';
import { useTracking } from '@shared/TrackingContext';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AppFeatures } from './feature-toggle/app-features';
import { TrackingEventName } from './gql/sdk';

interface IFeatureToggleAttributes {
  id: string;
  employee: boolean;
  country: 'GB' | 'DK';
  locale: string;
  role: 'CLIENT';
}
interface IFeatureToggleContext {
  growthBook: GrowthBook<AppFeatures>;
  setAttributes: (attributes: IFeatureToggleAttributes) => void;
  featuresLoaded: boolean;
  featureHash: number;
}

const FeatureToggleContext = React.createContext<IFeatureToggleContext>({
  growthBook: null,
  setAttributes: () => {
    // do nothing
  },
  featuresLoaded: false,
  featureHash: null,
});

export const FeatureToggleContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { trackEvent, anonymousId } = useTracking();
  const { country, locale } = useMemo(() => resolveIntl(), []);
  const { growthbook } = env;
  const [featureHash, setFeatureHash] = useState<number>();
  const growthBookFeaturesReady = useRef(false);

  const growthBook = useRef(
    new GrowthBook<AppFeatures>({
      apiHost: growthbook.apiHost,
      clientKey: growthbook.clientKey,
      attributes: {
        id: anonymousId || sessionStorage.getItem('userId'),
        employee: false,
        country,
        role: 'CLIENT',
        locale,
      },
      trackingCallback: (experiment, result) => {
        trackEvent(TrackingEventName.ExperimentStarted, {
          experiment_name: experiment.key,
          variant_name: result.key,
        });
      },
    }),
  ).current;

  const setAttributes = useCallback(
    (attributes: IFeatureToggleAttributes) => {
      growthBook.setAttributes(attributes);
    },
    [growthBook],
  );

  useEffect(() => {
    void (async () => {
      try {
        await growthBook.loadFeatures();
        const features = growthBook.getFeatures() || {};
        const attributes = growthBook.getAttributes() || {};

        setFeatureHash(simpleHash(JSON.stringify({ ...features, ...attributes })));
        growthBookFeaturesReady.current = true;
      } catch (e) {
        growthBookFeaturesReady.current = true;
      }
    })();
  }, [growthBook]);

  if (growthBookFeaturesReady.current && featureHash) {
    return (
      <FeatureToggleContext.Provider
        value={{
          growthBook,
          setAttributes,
          featuresLoaded: growthBookFeaturesReady.current,
          featureHash: featureHash,
        }}
      >
        {children}
      </FeatureToggleContext.Provider>
    );
  } else {
    return null;
  }
};

export const useFeatureToggleContext = () => {
  const context = useContext(FeatureToggleContext);
  return context;
};

export const useFeatureIsOn = (feature: keyof AppFeatures) => {
  const { growthBook } = useFeatureToggleContext();

  return growthBook?.isOn(feature) ?? false;
};

const simpleHash = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
};
