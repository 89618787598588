import { Stack } from '@mantine/core';
import { AddressFormUS } from '@pages/checkout/AddressFormUS';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { Benefits } from './Benefits';
import { MembershipSummary } from './MembershipSummary';
import { SwitchProgram } from './SwitchProgram';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const CheckoutUS: FunnelPageComponent<
  {
    address: Address;
    selectedProduct: ProductSelection;
    productChanged?: boolean;
  },
  IFunnelContext
> = ({
  data: { address },
  context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor, stateOfResidency },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const shippingAddress = useRef(address);
  const addressFormRef = useRef<{ validate: () => boolean }>();

  if (!address) {
    address = {};
  }

  if (!address.district) {
    address.district = stateOfResidency;
  }

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'enterYourDetails'} />} />
      <Stack gap="lg">
        <YourRecommendedMembership
          price={price}
          selectedProduct={selectedProduct}
          selected={true}
          onClick={() => {}}
          compact
          label={t('yourChosenProgram')}
        />

        {eligibleFor === ProductSelection.MedicationAndCoaching && (
          <SwitchProgram
            productChanged={true}
            selectedProduct={selectedProduct}
            onClick={() => {
              funnelApi.next({
                selectedProduct:
                  selectedProduct == ProductSelection.CoachingOnly
                    ? ProductSelection.MedicationAndCoaching
                    : ProductSelection.CoachingOnly,
                productChanged: true,
                address: shippingAddress.current,
              });
            }}
          />
        )}
        <AddressFormUS
          ref={addressFormRef}
          label={t('deliveryAddress')}
          address={address}
          onChange={(changedAddress) => {
            shippingAddress.current = changedAddress;
          }}
        />
        <MembershipSummary price={price} title={t('orderSummary')} />
        <TrustBoxMicroTrustScore />
        <Benefits selectedProduct={selectedProduct} />
      </Stack>
      <BottomScreenContainer>
        <NextButton
          onClick={() => funnelApi.next({ address: shippingAddress.current, selectedProduct: selectedProduct })}
          label={t('Checkout')}
        />
        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};
