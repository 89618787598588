import { ReactComponent as IconCheckbox } from '@assets/icons/icon-checkbox.svg';
import { Box, Group, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { DownloadButton } from '@shared/components/buttons/DownloadButton';
import { FeedbackLink } from '@shared/components/buttons/FeedbackLink';
import { GrowingButton } from '@shared/components/buttons/GrowingButton';
import { Icon } from '@shared/components/Icon';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { PhoneImage } from '@shared/components/PhoneImage';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import { useTranslation } from 'react-i18next';
import IFunnelContext from 'src/FunnelContext';

import { FaqCard } from './FaqCard';

export const CheckoutConfirmation: FunnelPageComponent<Record<string, never>, IFunnelContext> = ({ context }) => {
  const { t } = useTranslation();
  const isEmbeddedInApp = context.embedded;
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <FullScreenContainer gap="lg" py="md">
      <Stack align="center" gap="lg">
        <IconCheckbox height={40} />
        <StepTitle
          mt={0}
          mb={0}
          title={t('yourMembershipIsActivated')}
          description={
            <Stack justify="center" align="center" gap={0}>
              <Text ta="center" fw={400} size="lg" data-secondary data-secondary-font>
                {t('firstNameWelcomeToEmbla', { firstName: context.userSession.firstName })}
              </Text>
              <Text ta="center" fw={400} size="lg" data-secondary data-secondary-font>
                {t('weHaveSentAReceiptTo', { email: context.userSession.email })}
              </Text>
            </Stack>
          }
          description2={t('toGetStartedDownloadTheEmblaApp')}
        />
      </Stack>
      <Stack gap="lg">
        {!isEmbeddedInApp && <DownloadButton />}
        {isEmbeddedInApp && (
          <GrowingButton
            variant="transparent"
            buttonLabel={t('finish')}
            onClick={() => {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            }}
            leftSection={<Icon name="icon-fi-rr-check" size={20} />}
          />
        )}
        <FeedbackLink embedded={isEmbeddedInApp} source={context.source} />
        {!isMobile ? <FaqCard /> : null}
        <Box style={{ position: 'relative', width: '100%' }}>
          <PhoneImage />
          {isMobile ? (
            <Group style={{ width: '100%', position: 'absolute', bottom: 24, margin: 'auto' }}>
              <FaqCard />
            </Group>
          ) : null}
        </Box>
      </Stack>
    </FullScreenContainer>
  );
};
