import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Button, ButtonProps, Group } from '@mantine/core';
import { HTMLAttributeAnchorTarget, MouseEventHandler } from 'react';
interface IProps extends ButtonProps {
  buttonLabel?: string;
  onClick?: MouseEventHandler;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  type?: HTMLButtonElement['type'];
  forceGrow?: boolean;
}

export const GrowingButton: React.FunctionComponent<IProps> = ({
  type,
  buttonLabel,
  onClick,
  href,
  target = '_blank',
  forceGrow,
  ...buttonProps
}) => {
  const { isMobile } = useIsMobileViewport();

  return (
    <Group grow={forceGrow || isMobile} justify="center">
      {href ? (
        <Button
          type={type}
          miw={222}
          component="a"
          target={target}
          rel="noopener noreferrer"
          href={href}
          onClick={onClick}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button type={type} miw={222} onClick={onClick} {...buttonProps}>
          {buttonLabel}
        </Button>
      )}
    </Group>
  );
};
