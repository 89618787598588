import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { usePartnerSession } from '@util/helpers';
import { FunctionComponent, useEffect, useState } from 'react';

import { createPartnerFunnelConfig } from './configs/us-partner-signup/config';

import { t } from '@t';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'intro':
      return t('embla');
    case 'registration':
      return t('registration');
    case 'checkout':
      return t('checkout');
  }
};

export const FunnelPartnerSignupUS: FunctionComponent = () => {
  const { partnerInfo } = usePartnerSession();
  const [funnelConfig, setFunnelConfig] = useState<ReturnType<typeof createPartnerFunnelConfig>>();

  useEffect(() => {
    if (partnerInfo) {
      setFunnelConfig(
        createPartnerFunnelConfig({
          partnerCode: partnerInfo.code,
          partnerInfo,
          noUserDiscount: true,
          source: 'PHARMACY',
        }),
      );
    }
  }, [partnerInfo]);

  if (!funnelConfig) return null;
  return (
    <Funnel
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      debug={debugEnabled()}
      name="partner-signup"
      options={funnelConfig}
    />
  );
};
